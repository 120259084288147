import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { getParticipant, getPhoneBySerial, getReceivedSensorFilesBySerial, updateAccountLastSynched } from '../community-hats-js-library/utils/airtableFunctions';
import { store } from '../store/store';
import * as types from "../store/types"
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { RefATOField, RefATOInsideSetField, setATObject } from './StoreHooks';
import { getIntakeSurveyStatus, getPhonePlacementStatus, getSensorPlacementStatus, getWearablePlacementStatus } from '../community-hats-js-library/utils/devicesStatusFunctions';
import { getLastSync } from '../community-hats-js-library/utils/fitbitFunctions';


export function RefBundleDevices() {

    const setOfObjects = useSelector(state => state[locCon.AT_SET_OF_OBJECTS])

    return (Object.values(setOfObjects).map(ob => ob[locCon.AT_OBJECT_SENSOR]));
}

export const HasGovees = () => {
    const bundleDevices = RefBundleDevices()
    const [hasGovees, setHasGovees] = useState(() => false)


    useEffect(() => {

        const govee = bundleDevices.some(sens => sens[libCon.FIELDS][libCon.ATF_BRAND] === libCon.GOVEE)
        setHasGovees(govee)


    }, [bundleDevices])

    return (hasGovees)

}


export const HasKestrels = () => {
    const bundleDevices = RefBundleDevices()
    const [hasKestrel, setHasKestrel] = useState(() => false)


    useEffect(() => {

        const kestrel = bundleDevices.some(sens => sens[libCon.FIELDS][libCon.ATF_BRAND] === libCon.KESTREL)
        setHasKestrel(kestrel)


    }, [bundleDevices])

    return (hasKestrel)

}

export const HasHobo = () => {
    const bundleDevices = RefBundleDevices()
    const [hasHobo, setHasHobo] = useState(() => false)


    useEffect(() => {

        const hobo = bundleDevices.some(sens => sens[libCon.FIELDS][libCon.ATF_BRAND] === libCon.HOBO)
        setHasHobo(hobo)


    }, [bundleDevices])

    return (hasHobo)

}

export const HasMXHobo = () => {
    const bundleDevices = RefBundleDevices()
    const [hasMXHobo, setHasMXHobo] = useState(() => false)


    useEffect(() => {

        const hobo = bundleDevices.some(sens => sens[libCon.FIELDS][libCon.ATF_BRAND] === libCon.HOBO && sens[libCon.FIELDS][libCon.ATF_MODEL] === libCon.MX_HOBO_MODEL)
        setHasMXHobo(hobo)


    }, [bundleDevices])

    return (hasMXHobo)

}


export const refreshSensorStatus = async (positionId) => {

    let sensorSerial = store.getState()[locCon.AT_SET_OF_OBJECTS][positionId][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL]

    let [response, sensorFiles] = await getReceivedSensorFilesBySerial(sensorSerial)

    if (response === libCon.OK) {
        let payload = { [libCon.KEY]: sensorSerial, [libCon.VALUE]: Object.values(sensorFiles) }

        store.dispatch({
            type: types.UPDATE_RECEIVED_FILES_FOR_SENSOR,
            payload: payload
        })

    }

    return response


}

export const RefReceivedSensorFiles = (positionId) => {


    const serial = useSelector(state => state[locCon.AT_SET_OF_OBJECTS][positionId][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL])
    const fileReference = useSelector(state => isNullOrUndefined(serial) ? [] : state[locCon.AT_RECEIVED_SENSOR_FILES][serial])


    const [files, setFiles] = useState([])

    useEffect(() => {

        if (!isNullOrUndefined(fileReference))
            setFiles(fileReference)
        else
            setFiles([])


    }, [serial, fileReference])


    return files

}


export const RefSensorStatus = (positionId) => {

    const [status, setStatus] = useState(libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT)
    const [message, setMessage] = useState(libCon.MESSAGE_INCOMPLETE_DEPLOYMENT)

    // Information from sensor
    const latestFiles = RefReceivedSensorFiles(positionId)
    const model = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_MODEL)
    const brand = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_BRAND)
    const lastBatteryChange = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_LATEST_BATTERY_CHANGE)
    const sensorRecordCreated = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_DATE_CREATED)
    const placementStart = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_START_DATE)

    useEffect(() => {

        let finalLastBatteryChange = lastBatteryChange
        if (isNullOrUndefined(lastBatteryChange))
            finalLastBatteryChange = sensorRecordCreated

        let latestHouseVisit = new Date()
        if (!isNullOrUndefined(latestFiles) &&
            !isNullOrUndefined(model) &&
            !isNullOrUndefined(brand) &&
            !isNullOrUndefined(finalLastBatteryChange) &&
            !isNullOrUndefined(placementStart)) {
            let [newStatus, newMessage] = getSensorPlacementStatus(brand, model, latestFiles, latestHouseVisit, new Date(placementStart), new Date(finalLastBatteryChange))

            setStatus(newStatus)
            setMessage(newMessage)

        }


    }, [latestFiles, model, brand, lastBatteryChange, placementStart, sensorRecordCreated])


    return [status, message]


}

export const RefPhoneStatus = () => {


    const [status, setStatus] = useState(libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT)
    const [message, setMessage] = useState(libCon.MESSAGE_INCOMPLETE_DEPLOYMENT)

    // Information from sensor
    const latestUpload = RefATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_LAST_UPLOADED)

    useEffect(() => {

        let latestHouseVisit = new Date()
        if (!isNullOrUndefined(latestUpload)) {
            let [newStatus, newMessage] = getPhonePlacementStatus(new Date(latestUpload), latestHouseVisit)

            setStatus(newStatus)
            setMessage(newMessage)

        }


    }, [latestUpload])


    return [status, message]

}


export const refreshPhoneStatus = async () => {

    let phoneSerial = store.getState()[locCon.AT_OBJECT_PHONE][libCon.FIELDS][libCon.ATF_SERIAL]
    let phoneId = store.getState()[locCon.AT_OBJECT_PHONE][libCon.ID]


    let [response, phones] = await getPhoneBySerial(phoneSerial)


    if (response === libCon.OK) {

        if (phoneId in phones)
            setATObject(locCon.AT_OBJECT_PHONE, phoneId, phones[phoneId])
        else
            response = libCon.NOT_FOUND_ERROR

    }

    return response


}




export const RefWearableStatus = () => {


    const [status, setStatus] = useState(libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT)
    const [message, setMessage] = useState(libCon.MESSAGE_INCOMPLETE_DEPLOYMENT)

    // Information from sensor
    const latestUpload = RefATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_LAST_UPLOADED)

    useEffect(() => {

        let latestHouseVisit = new Date()
        if (!isNullOrUndefined(latestUpload)) {
            let [newStatus, newMessage] = getWearablePlacementStatus(new Date(latestUpload), latestHouseVisit)

            setStatus(newStatus)
            setMessage(newMessage)

        }


    }, [latestUpload])


    return [status, message]

}


export const refreshWearableStatus = async () => {

    let accountId = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.ID]
    let requestId = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.FIELDS][libCon.ATF_FITBIT_REQUEST_ID]
    let token = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.FIELDS][libCon.ATF_FITBIT_TOKEN]
    let email = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.FIELDS][libCon.ATF_EMAIL]


    let [fitbitResponse, lastSynched] = await getLastSync(requestId, token)

    if (fitbitResponse !== libCon.OK)
        return fitbitResponse


    let [response, accounts] = await updateAccountLastSynched(email, lastSynched)


    if (response === libCon.OK) {

        if (accountId in accounts)
            setATObject(locCon.AT_OBJECT_EMAIL_ACCOUNT, accountId, accounts[accountId])
        else
            response = libCon.NOT_FOUND_ERROR

    }

    return response


}



export const RefIntakeSurveyStatus = () => {


    const [status, setStatus] = useState(libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT)
    const [message, setMessage] = useState(libCon.MESSAGE_INCOMPLETE_DEPLOYMENT)

    // Information from participant
    const intakeStatus = RefATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_INTAKE_STATUS)

    useEffect(() => {

        let [newStatus, newMessage] = getIntakeSurveyStatus(intakeStatus)

        setStatus(newStatus)
        setMessage(newMessage)


    }, [intakeStatus])


    return [status, message]

}


export const refreshIntakeSurveyStatus = async () => {

    let internalId = store.getState()[locCon.AT_OBJECT_PARTICIPANT][libCon.ID]


    let [response, participants] = await getParticipant(internalId)


    if (response === libCon.OK) {

        if (internalId in participants)
            setATObject(locCon.AT_OBJECT_PARTICIPANT, internalId, participants[internalId])
        else
            response = libCon.NOT_FOUND_ERROR

    }

    return response


}
