import * as locCon from './LocalConstants';

import './App.css';

import Title from 'antd/es/typography/Title';
import { RefCurrentPage } from './hooks/NavigationHooks';
import ManagerMainApp from './pages/ManagerMainApp';
import BundleSetUp from './pages/BundleSetUp';
import BundleDeploy from './pages/BundleDeploy';
import ToolsMainPage from './pages/ToolsMainPage';
import ToolLocationExctractor from './pages/ToolLocationExctractor';
import BundleCollectData from './pages/BundleCollectData';
import ToolFitbitTokenUpdater from './pages/ToolFitbitTokenUpdater';
import BundleCheckStatus from './pages/BundleCheckStatus';



function App() {


  // Navigation
  const currentPage = RefCurrentPage();

  // Uses a function through switch to render
  const renderComponents = (page) => {

    switch (page) {
      case locCon.PAGE_MANAGER_START:
        return (<ManagerMainApp />)
      case locCon.PAGE_SET_UP_BUNDLE:
        return (<BundleSetUp />)
      case locCon.PAGE_DEPLOY_BUNDLE:
        return (<BundleDeploy />)
      case locCon.PAGE_COLLECT_DATA:
        return (<BundleCollectData />)
      case locCon.PAGE_BUNDLE_STATUS:
        return (<BundleCheckStatus />)
      case locCon.PAGE_TOOLS_MAIN:
        return (<ToolsMainPage />)
      case locCon.PAGE_TOOL_LOCATION_EXTRACTOR:
        return (<ToolLocationExctractor />)
      case locCon.PAGE_TOOL_FITBIT_API_UPDATER:
        return (<ToolFitbitTokenUpdater />)
      default:
        return (<Title level={2}>{`Deployment and Collection Monitoring`}</Title>)
    }
  }

  return (
    <div>
      {renderComponents(currentPage)}
    </div>
  );
}

export default App;


