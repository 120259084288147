import Header from '../components/Header';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { HasGovees, HasKestrels, HasMXHobo } from '../hooks/BundleDeviceHooks';
import { useRef } from 'react';
import { getATOField } from '../hooks/StoreHooks';
import { showNotification } from '../utils/generalFunctions';
import CustomButton from '../elements/CustomButton';
import { SimpleCopyField } from '../elements/SimpleStoreCopyField';
import ParticipantIdUpdater from '../components/ParticipantIdUpdater';
import FitbitTokenUpdater from '../components/FitbitTokenUpdater';
import CheckboxGroup from '../elements/CheckboxGroup';


export function BundleCollectData() {

  const i18n = RefLanguage()



  const hasGovees = HasGovees()
  const hasKestrel = HasKestrels()
  const hasMXHobo = HasMXHobo()

  const restartProcess = () => {
    //RestartDataCollection()
    showNotification(i18n.t("dataCollectionRestarted"))

  }

  const needsToUpdate = useRef(getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID) === libCon.MISSING)


  const needsToUpdateFitbitApiCompute = () => {
    let currentClientId = getATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_FITBIT_CLIENT_ID)
    return (currentClientId === null || currentClientId === undefined || currentClientId === libCon.FITBIT_TEMP_APPLICATION_ID)
  }


  const needsToUpdateFitbitApi = useRef(needsToUpdateFitbitApiCompute())


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>


        <Header long={false} />
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToCollect")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

        <div className="horizontalLine" />



        {/* Update SEWA ID */}
        {
          needsToUpdate.current === true
            ? <div>
              <ParticipantIdUpdater />
              <div className="horizontalLine" />
            </div>
            : <div></div>
        }

        {/* Update Fitbit Api */}
        {
          needsToUpdateFitbitApi.current === true
            ? <div>
              <FitbitTokenUpdater />
              <div className="horizontalLine" />

            </div>
            : <div></div>
        }



        {/* Govee Sensor Data */}
        {
          hasGovees === true
            ? <div className='verticalSection'>
              <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("GoveeSensorData")}</CustomText>
              <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("GoveeSensorDataText")}</CustomText>
              <CheckboxGroup
                mainCheckbox={{ [libCon.ID]: locCon.GS_COLLECT_SENSOR_DATA, [libCon.TEXT]: i18n.t("collectSensorData") }}
                checkBoxArray={[{ [libCon.ID]: locCon.GD_SYNC_DEVICES, [libCon.TEXT]: i18n.t("syncDevices") },
                { [libCon.ID]: locCon.GD_SEND_EMAIL, [libCon.TEXT]: i18n.t("sendEmail") }

                ]} />
              <div className="horizontalLine" />
            </div>
            : <div></div>
        }

        {/* Kestrel Sensor Data */}
        {
          hasKestrel === true
            ? <div className="verticalSection">
              <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("KestrelSensorData")}</CustomText>
              <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("KestrelSensorDataText")}</CustomText>
              <CheckboxGroup
                mainCheckbox={{ [libCon.ID]: locCon.KS_COLLECT_SENSOR_DATA, [libCon.TEXT]: i18n.t("collectSensorData") }}
                checkBoxArray={[{ [libCon.ID]: locCon.KD_SYNC_DEVICES, [libCon.TEXT]: i18n.t("syncDevice") },
                {
                  [libCon.ID]: locCon.KD_SEND_EMAIL,
                  [libCon.TEXT]: i18n.t("sendEmail"),
                  [libCon.CONTAINER]: <SimpleCopyField title={i18n.t("sensorInbox")} value={libCon.SENSOR_INBOX_EMAIL} />
                }

                ]} />
              <div className="horizontalLine" />
            </div>
            : <div></div>
        }

        {/* MX Hobo Sensor Data */}
        {
          hasMXHobo === true
            ? <div className="verticalSection">
              <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("MXHoboSensorData")}</CustomText>
              <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("MXHoboSensorDataText")}</CustomText>
              <CheckboxGroup
                mainCheckbox={{ [libCon.ID]: locCon.MXHOBO_COLLECT_SENSOR_DATA, [libCon.TEXT]: i18n.t("collectSensorData") }}
                checkBoxArray={[{ [libCon.ID]: locCon.MXHOBO_SYNC_DEVICES, [libCon.TEXT]: i18n.t("connectDevice") },
                { [libCon.ID]: locCon.MXHOBO_DOWNLOAD_DATA, [libCon.TEXT]: i18n.t("downloadData") },
                {
                  [libCon.ID]: locCon.MXHOBO_SEND_EMAIL,
                  [libCon.TEXT]: i18n.t("exportAndSendEmail"),
                  [libCon.CONTAINER]: <SimpleCopyField title={i18n.t("sensorInbox")} value={libCon.SENSOR_INBOX_EMAIL} />
                }

                ]} />
              <div className="horizontalLine" />

            </div>
            : <div></div>
        }

        {/* Wearable Data */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("wearableData")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("wearableDataText")}</CustomText>
        <CheckboxGroup
          mainCheckbox={{ [libCon.ID]: locCon.WD_SYNC_WEARABLE_DATA, [libCon.TEXT]: i18n.t("syncDevice") }} />
        <div className="horizontalLine" />


        {/* Location Data */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("locationData")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("locationDataText")}</CustomText>
        <CheckboxGroup
          mainCheckbox={{ [libCon.ID]: locCon.LD_COLLECT_LOCATION_DATA, [libCon.TEXT]: i18n.t("collectLocationData") }}
          checkBoxArray={[{ [libCon.ID]: locCon.LD_EXPORT_DATA, [libCon.TEXT]: i18n.t("exportData") },
          { [libCon.ID]: locCon.LD_RESET_TRACKING, [libCon.TEXT]: i18n.t("resetTracking") },
          { [libCon.ID]: locCon.LD_TURN_ON_LOCATION, [libCon.TEXT]: i18n.t("turnOnLocation") },


          ]} />
        <div className="horizontalLine" />




      </div>
    </div>
  );
}

export default BundleCollectData


