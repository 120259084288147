import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import CustomButton from '../elements/CustomButton';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';


export function ToolsMainPage() {

  const i18n = RefLanguage()

  // Current Page
  const setCurrentPage = RefSetCurrentPage()


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} />

        {/* Available Tools*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("availableTools")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_TOOL_FITBIT_API_UPDATER)}>{i18n.t("updateFitbitAPI")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_TOOL_LOCATION_EXTRACTOR)}>{i18n.t("locationExtractor")}</CustomButton>
        <div className={"horizontalLine"} />



      </div>
    </div>
  );
}

export default ToolsMainPage


