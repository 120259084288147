import React from "react";
import * as locCon from "../LocalConstants"
import CustomText from "./CustomText";
import { ExportOutlined } from "@ant-design/icons";
import { goToThirdPartyLink } from "../community-hats-js-library/utils/generalFunctions";



function SimpleLink({ text, link, onClickCleanup }) {
    return (
        <div className="horizontalSectionCenter">
            <CustomText type={locCon.ELEMENT_TEXT}>{text}</CustomText>
            <ExportOutlined style={{ marginLeft: 15, color: "var(--primary-color-5)", fontSize: 30 }} onClick={() => { goToThirdPartyLink(link); onClickCleanup(); }} />
        </div>


    )
}

SimpleLink.defaultProps = {
    onClickCleanup: (val) => true
};

export default SimpleLink






