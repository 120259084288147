import React, { useEffect } from "react";
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { StateStoredBooleanValue, StateStoredMultipleBooleans } from "../hooks/StoreHooks";
import { Typography } from "antd";
import { ExportOutlined, InfoCircleOutlined } from "@ant-design/icons";
import CustomCheckBox from "./CustomCheckBox";
import links from './../data/links.json';
import { goToThirdPartyLink, isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions";

const { Text } = Typography;

export function CheckboxGroup({ mainCheckbox, checkBoxArray }) {

  // All booleans
  const [booleanValues, setBooleanValues] = StateStoredMultipleBooleans(checkBoxArray.map(ob => ob[libCon.ID]))

  // Main Booleans
  const [mainBoolean, setMainBoolean] = StateStoredBooleanValue(mainCheckbox[libCon.ID])


  // Sets the value
  const setValue = async (key, value) => {

    if (booleanValues[key] !== value)
      setBooleanValues({ ...booleanValues, [key]: value })
  }


  // Toggle All
  const toggleAll = (val) => {

    let newDict = {}
    checkBoxArray.forEach(ob => newDict[ob[libCon.ID]] = val)


    setMainBoolean(val)
    setBooleanValues(newDict)

  }

  // Check if any sub checkbox change
  useEffect(() => {


    if (checkBoxArray.length > 0) {
      let newVal = Object.values(booleanValues).every(value => value === true)
      setMainBoolean(newVal)
    }


  }, [booleanValues, checkBoxArray, setMainBoolean])


  return (

    <div style={styles.checkboxContainer}>
      <div style={styles.checkboxLevel1Container}>
        <CustomCheckBox type={locCon.MAIN_PROGRESS_CHECKBOX} checked={mainBoolean} onChange={(val) => toggleAll(val.target.checked)} />
        <Text style={styles.checkboxLevel1Text} onClick={() => toggleAll(!mainBoolean)}>{mainCheckbox[libCon.TEXT]}</Text>
        {
          mainCheckbox[libCon.ID] in links && !isNullOrUndefined(links[mainCheckbox[libCon.ID]][libCon.INFO])
            ? <InfoCircleOutlined style={{ marginLeft: 15, color: "var(--primary-color-5)", fontSize: 30 }} onClick={() => goToThirdPartyLink(links[mainCheckbox[libCon.ID]][libCon.INFO])} />
            : <div></div>
        }
        {
          mainCheckbox[libCon.ID] in links && !isNullOrUndefined(links[mainCheckbox[libCon.ID]][libCon.LINK])
            ? <ExportOutlined style={{ marginLeft: 15, color: "var(--primary-color-5)", fontSize: 30 }} onClick={() => goToThirdPartyLink(links[mainCheckbox[libCon.ID]][libCon.LINK])} />
            : <div></div>
        }
      </div>
      {
        mainCheckbox[libCon.CONTAINER] !== undefined
          ? mainCheckbox[libCon.CONTAINER]
          : <div></div>
      }
      {
        checkBoxArray.map((ob, i) =>
          <div key={ob[libCon.ID]}>
            <div style={styles.checkboxLevel2Container}>
              <CustomCheckBox type={locCon.SECONDARY_PROGRESS_CHECKBOX} checked={booleanValues[ob[libCon.ID]]} onChange={(val) => setValue(ob[libCon.ID], val.target.checked)} />
              <Text style={styles.checkboxLevel2Text} onClick={(val) => setValue(ob[libCon.ID], !booleanValues[ob[libCon.ID]])}>{ob[libCon.TEXT]}</Text>
              {
                ob[libCon.ID] in links && !isNullOrUndefined(links[ob[libCon.ID]][libCon.INFO])
                  ? <InfoCircleOutlined style={{ marginLeft: 15, color: "var(--primary-color-5)", fontSize: 30 }} onClick={() => goToThirdPartyLink(links[ob[libCon.ID]][libCon.INFO])} />
                  : <div></div>
              }
              {
                ob[libCon.ID] in links && !isNullOrUndefined(links[ob[libCon.ID]][libCon.LINK])
                  ? <ExportOutlined style={{ marginLeft: 15, color: "var(--primary-color-5)", fontSize: 30 }} onClick={() => goToThirdPartyLink(links[ob[libCon.ID]][libCon.LINK])} />
                  : <div></div>
              }
            </div>
            {
              ob[libCon.CONTAINER] !== undefined
                ? ob[libCon.CONTAINER]
                : <div></div>
            }
          </div>
        )
      }
    </div>
  );
}

// Define default props
CheckboxGroup.defaultProps = {
  checkBoxArray: [],
  links: {}
};

export default CheckboxGroup;


const styles = {

  checkboxContainer:
  {
    width: "100%",
    marginLeft: "3vw",
    marginBottom: "3vh"
  },
  checkboxLevel1Container: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "left",
    alignItems: 'center',
    marginLeft: 3,
    marginTop: 6,


  },
  checkboxLevel1Text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  checkboxLevel2Container: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "left",
    alignItems: 'center',
    marginLeft: 15,
    marginTop: 6

  },
  checkboxLevel2Text: {
    fontSize: 14,
    fontWeight: "bold",
  },
  checkbox: {
    marginRight: 8,
    marginTop: 3,
    height: 35,
    width: 35,

  },

}










