import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

function CustomActivityIndicator({ size }) {
  return (
    <Spin indicator={<LoadingOutlined spin />} size={size} style={{ marginBottom: "3vh" }} />
  )
}

CustomActivityIndicator.defaultProps = {
  size: "large"
};


export default CustomActivityIndicator