import React, { useState } from 'react'
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import CollectionStatus from '../elements/CollectionStatus';
import { SimpleCopyField, SimpleCopyFieldFromATO } from '../elements/SimpleStoreCopyField';
import { RefPhoneStatus, refreshPhoneStatus } from '../hooks/BundleDeviceHooks';
import { showErrorDialogByCode } from '../utils/dialogFunctions';
import { showNotification } from '../utils/generalFunctions';
import CustomSpin from '../elements/CustomSpin';
import { CustomPrimaryButtonWithDisability } from '../elements/CustomButton';
import { RefATOField } from '../hooks/StoreHooks';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { formatDistanceShort, formatToIndiaTime } from '../community-hats-js-library/utils/dateFunctions';

function PhoneStatusViewer() {
    const i18n = RefLanguage()


    const [status, message] = RefPhoneStatus()

    const serial = RefATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_SERIAL)
    const lastUploaded = RefATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_LAST_UPLOADED)


    const [isSynching, setisSynching] = useState(false)


    const refreshRecords = async () => {
        setisSynching(true)
        let response = await refreshPhoneStatus()

        if (response !== libCon.OK)
            showErrorDialogByCode(response)
        else
            showNotification(i18n.t("refreshComplete"))

        setisSynching(false)
    }





    return (
        <div className='verticalSection'>
            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("locationTrackerApp")}</CustomText>

            <CollectionStatus status={status} message={message} />

            <SimpleCopyFieldFromATO title={i18n.t("serial")} atoId={locCon.AT_OBJECT_PHONE} fieldId={libCon.ATF_SERIAL} />

            <SimpleCopyField title={i18n.t("lastUploaded")} value={isNullOrUndefined(lastUploaded) ? "---" : `${formatToIndiaTime(lastUploaded, true)} (${formatDistanceShort(lastUploaded)})`} enableCopy={false} />

            {
                isSynching
                    ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                    : <CustomPrimaryButtonWithDisability isDisabled={isNullOrUndefined(serial)} title={i18n.t("incompleteDeployment")} description={i18n.t("addSerial")} onClick={() => refreshRecords()}>{i18n.t("refresh")}</CustomPrimaryButtonWithDisability>
            }


        </div>
    )
}

export default PhoneStatusViewer