import React from 'react'
import CustomButton from '../elements/CustomButton'
import { RefLanguage } from '../hooks/LanguageHooks'
import * as locCon from "../LocalConstants"
//import { getClipboardValue } from '../GeneralFunctions'
import { setStoredValue } from '../hooks/StoreHooks'

function PasteFitbitAPIValues() {

    const i18n = RefLanguage()

    const pasteValues = async () => {


        // DEBUG
        // TODO
        // REMOVE

        setStoredValue(locCon.FITBIT_TOKEN, "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyM1MyRksiLCJzdWIiOiJCV1JRVloiLCJpc3MiOiJGaXRiaXQiLCJ0eXAiOiJhY2Nlc3NfdG9rZW4iLCJzY29wZXMiOiJyYWN0IHJzZXQgcmhyIHJwcm8gcnRlbSByc2xlIiwiZXhwIjoxNzQ4NjA3OTMwLCJpYXQiOjE3MjYyMzg4OTZ9.9qnXuBR09G69tE3nWULXFiVCJMUaPJftN8nNG4YyD-w")
        setStoredValue(locCon.FITBIT_REQUEST_ID, "BWRQVZ")


        // let vals = await getClipboardValue()
        // const splitArray = vals.split (libCon.FITBIT_API_SEP)

        // setStoredValue (locCon.FITBIT_TOKEN, splitArray[0])
        // setStoredValue (locCon.FITBIT_REQUEST_ID, splitArray[1])



    }


    return (
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={pasteValues}>
            {i18n.t("pasteBothValues")}
        </CustomButton>
    )
}

export default PasteFitbitAPIValues