import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import * as locCon from "../LocalConstants"
import CustomText from './CustomText'


const globalCheckboxStyles = {
    marginRight: "5px"
}



function CustomCheckBox({ type, ...props }) {

    switch (type) {
        case locCon.MAIN_PROGRESS_CHECKBOX:
            return (<Checkbox className='mainProgressCheckbox'
                style={{ ...globalCheckboxStyles }}
                {...props} />)
        case locCon.SECONDARY_PROGRESS_CHECKBOX:
            return (<Checkbox className='secondaryProgressCheckbox'
                style={{ ...globalCheckboxStyles }}
                {...props} />)
        case locCon.DEFAULT_OPTION_CHECKBOX:
            return (<Checkbox className='defaultProgressCheckbox'
                style={{ ...globalCheckboxStyles }}
                {...props} />)

        default:
            return (<Checkbox  {...props} />)
    }


}

export default CustomCheckBox



export function CheckboxDefaultValue({ value, setValue, defaultValue, setEditingValue, text }) {


    const [selected, setSelected] = useState(() => value === defaultValue)

    useEffect(() => {
        setSelected(value === defaultValue)
    }, [value, defaultValue])



    const setDefaultValue = (val) => {
        if (val) {
            setValue(defaultValue)
            setEditingValue(false)
        }
        else
            setValue(null)
    }

    return (
        <div className='horizontalSection' style={{ marginBottom: "2vh" }}>
            <div style={{ width: "75%", maxWidth: "75%", marginRight: "1vh", textAlign: "center" }}><CustomText type={locCon.DEFAULT_INFO_TEXT}>{text}</CustomText></div>
            <CustomCheckBox type={locCon.DEFAULT_OPTION_CHECKBOX} checked={selected} onChange={(val) => setDefaultValue(val.target.checked)} />
        </div>

    );
}


