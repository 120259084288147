import { Button, Popconfirm } from 'antd'
import React from 'react'
import * as locCon from "../LocalConstants"
import { RefLanguage } from '../hooks/LanguageHooks'
import { isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions'


const globalButtonStyles = {
    textDecoration: "none"
}

function CustomButton({ type, ...props }) {

    let { style, ...otherProps } = props
    if (isNullOrUndefinedOrEmpty(style))
        style = {}

    switch (type) {
        case locCon.PRIMARY_BUTTON_DISSABLED_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-4)',
                borderColor: 'var(--primary-color-4)',
                color: "#111111",
                width: "80%",
                marginLeft: "10%",
                marginRight: "10%",
                marginBottom: "3vh",
                ...style
            }}
                {...otherProps} />)

        case locCon.PRIMARY_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-3)',
                borderColor: 'var(--primary-color-3)',
                color: "#ffffff",
                width: "80%",
                marginLeft: "10%",
                marginRight: "10%",
                marginBottom: "3vh",
                ...style
            }}
                {...otherProps} />)

        case locCon.DANGER_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-2-transparent)',
                borderColor: 'var(--primary-color-3)',
                color: "#000000",
                width: "80%",
                marginLeft: "10%",
                marginRight: "10%",
                marginBottom: "3vh",
                ...style
            }}
                {...otherProps} />)

        case locCon.SAVE_EDIT_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-3)',
                borderColor: 'var(--primary-color-3)',
                color: "#ffffff",
                width: "50px",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        case locCon.SAVE_EDIT_BUTTON_DISABLED:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-4)',
                borderColor: 'var(--primary-color-4)',
                color: "#111111",
                width: "50px",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        case locCon.MODULE_REGULAR_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-3)',
                borderColor: 'var(--primary-color-3)',
                color: "#ffffff",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        case locCon.MODULE_CANCEL_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-5)',
                borderColor: "#000000",
                color: "#000000",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        default:
            return (<Button  {...props} />)
    }


}

export const CustomPrimaryButtonWithDisability = ({ isDisabled, title, description, ...props }) => {

    const i18n = RefLanguage()

    return (
        isDisabled
            ? <Popconfirm style={{ maxWidth: "100%" }} okText={i18n.t("ok")} title={title} description={description} showCancel={false} placement="top">
                <CustomButton type={locCon.PRIMARY_BUTTON_DISSABLED_BUTTON} {...props} onClick={() => false} />
            </Popconfirm>
            : <CustomButton type={locCon.PRIMARY_BUTTON} {...props} />
    )

}

export default CustomButton