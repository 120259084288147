import { Spin } from 'antd'
import React from 'react'
import * as locCon from "../LocalConstants"


function CustomSpin({ type, ...props }) {

    switch (type) {
        case locCon.BUTTON_SPINNER:
            return (<div style={{ marginBottom: "4vh" }}>
                <Spin {...props} />
            </div>)
        default:
            return <Spin {...props} />
    }


}


export default CustomSpin