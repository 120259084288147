import React from 'react'
import { RefLanguage } from '../hooks/LanguageHooks'
import { SymbolForStatus } from '../community-hats-js-library/utils/devicesStatusFunctions'
import CustomText from './CustomText'
import * as locCon from "../LocalConstants"
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions'

function CollectionStatus({ status, message, title }) {

    const i18n = RefLanguage()


    return (
        <div className='verticalSection'>
            <CustomText type={locCon.ELEMENT_TITLE_TEXT} style={{ marginTop: "1vh" }}>{isNullOrUndefined(title) ? i18n.t("collectionStatus") : title}</CustomText>
            <SymbolForStatus status={status} message={message} />
            <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: "1.5vh" }}>{message}</CustomText>
        </div>
    )
}

export default CollectionStatus