// Airtable functions
import Airtable from "airtable";
import { format, formatISO, subMonths } from "date-fns";
import * as libCon from '../Constants';
import { nowInIndia } from "./dateFunctions";
import { exportToExcel } from "./ExcelFunctions";
import { arrayToDict, filterObjectByKeys, isNull } from "./generalFunctions";


// Airtable
const at_base = new Airtable({ apiKey: libCon.CONFIG[libCon.AT_API_KEY] }).base(libCon.CONFIG[libCon.AT_APP_ID]);



// General Methods
export const getDataFromTable = async (tableId) => {

    let records = await at_base(tableId).select().all();
    records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

    records = arrayToDict(records, libCon.ID)

    return (records)

}


// Specific Methods
export const getAllParticipants = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_PARTICIPANTS])
    return (records)
}

export const getAllSensors = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_SENSORS])
    return (records)
}

export const getAllHouses = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_HOUSES])
    return (records)
}

export const getAllSensorHousePlacement = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_SENSOR_HOUSE_PLACEMENT])
    return (records)
}

export const getAllParticipantPlacement = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_PARTICIPANTS_PLACEMENT])
    return (records)
}

export const getAllPhones = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_PHONES])
    return (records)
}


export const getAllPhonesPlacement = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_PHONE_PLACEMENTS])
    return (records)
}

export const getAllWearables = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_WEARABLES])
    return (records)
}

export const getAllWearablePlacements = async () => {
    const records = await getDataFromTable(libCon.CONFIG[libCon.AT_TABLE_WEARABLE_PLACEMENTS])
    return (records)
}

export const getAllPendingIssues = async () => {
    let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_ISSUES]).select({
        filterByFormula: `${libCon.ATF_STATUS} = '${libCon.ATF_PENDING}'`
    }).all();

    records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
    records = arrayToDict(records, libCon.ID)

    return (records)
}

export const getAllReceivedSensorFiles = async (monthsAgo = 2) => {

    const startDate = subMonths(new Date(), monthsAgo);

    let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_RECEIVED_SENSOR_FILES]).select({
        filterByFormula: `IS_AFTER({${libCon.ATF_DATE_UPLOADED}}, '${formatISO(startDate)}')`
    }).all();

    records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

    records = arrayToDict(records, libCon.ID)

    return (records)

}

export const getPhoneBySerial = async (serial) => {

    try {


        let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_PHONES]).select({
            filterByFormula: `${libCon.ATF_SERIAL} = '${serial}'`
        }).all();
        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

        records = arrayToDict(records, libCon.ID)

        return [libCon.OK, records]

    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const getReceivedSensorFilesBySerial = async (serial) => {

    try {


        let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_RECEIVED_SENSOR_FILES]).select({
            filterByFormula: `${libCon.ATF_SERIAL} = '${serial}'`
        }).all();
        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

        records = arrayToDict(records, libCon.ID)

        return [libCon.OK, records]

    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const getSensorBySerial = async (serial) => {
    let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_SENSORS]).select({
        filterByFormula: `${libCon.ATF_SERIAL} = '${serial}'`
    }).all();
    records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

    records = arrayToDict(records, libCon.ID)

    return (records)
}


export const getParticipant = async (internalId) => {


    try {

        let r = await at_base(libCon.CONFIG[libCon.AT_TABLE_PARTICIPANTS]).find(internalId)

        let records = {
            [internalId]: {
                ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id
            }
        }

        return ([libCon.OK, records])

    } catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const updateAccountLastSynched = async (email, lastSynched) => {
    let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_EMAILS]).select({
        filterByFormula: `{${libCon.ATF_EMAIL}} = '${email}'`
    }).all();


    records = await Promise.all(records.map(async r =>
        await at_base(libCon.CONFIG[libCon.AT_TABLE_EMAILS]).update(r.id, { [libCon.ATF_LAST_UPLOADED]: lastSynched })
    ))




    records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
    records = arrayToDict(records, libCon.ID)

    return (records)


}


export const exportParticipantsTable = async () => {
    const cols = [libCon.ATF_PUBLIC_ID, libCon.ATF_ACTIVE, libCon.ATF_SEWA_ID, libCon.ATF_TYPE, libCon.ATF_NAME, libCon.ATF_LAST_NAME, libCon.ATF_PHONE_NUMBER, libCon.ATF_JOINED_DATE, libCon.ATF_AREA, libCon.ATF_NOTES]

    let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_PARTICIPANTS]).select({ view: libCon.AT_VIEW_PARTICIPANTS_PUBLIC }).all();
    records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

    // filters
    records = records.map(r => filterObjectByKeys(r, cols))


    // Exports to Excel
    const now = new Date()
    exportToExcel(records, `Participants SEWA ${format(now, "yyyy-MM-d")}.xlsx`, cols)

    return true

}


export const logBatteryChange = async (serial, date, time) => {

    let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_SENSORS]).select({
        filterByFormula: `{${libCon.ATF_SERIAL}} = '${serial}'`
    }).all();


    // Update
    let dateString = `${date} ${time}`

    records = await Promise.all(records.map(async r => await at_base(libCon.CONFIG[libCon.AT_TABLE_SENSORS]).update(r.id, { [libCon.ATF_LATEST_BATTERY_CHANGE]: dateString })))


    records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
    records = arrayToDict(records, libCon.ID)


    return (records)



}


export const markIssueAsDone = async (issue, noteText) => {

    let newText = issue[libCon.ATF_NOTES] === undefined || issue[libCon.ATF_NOTES] === null ? "" : `${issue[libCon.ATF_NOTES]}\n`

    if (!isNull(noteText))
        newText = `${newText}${nowInIndia()}: ${noteText}`

    let newIssue = await at_base(libCon.CONFIG[libCon.AT_TABLE_ISSUES]).update(issue[libCon.ID], {
        [libCon.ATF_STATUS]: libCon.ATF_DONE,
        [libCon.ATF_DATE_SOLVED]: nowInIndia(),
        [libCon.ATF_NOTES]: newText
    })

    let records = [newIssue]

    records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
    records = arrayToDict(records, libCon.ID)


    return (records)



}



export const getLatestGoveeActivationCodes = async (maxRecords = 4) => {


    try {

        let records = await at_base(libCon.CONFIG[libCon.AT_TABLE_GOVEE_ACTIVATION_CODES_ID]).select({
            maxRecords: maxRecords,
            sort: [{ field: libCon.ATF_DATE_CREATED, direction: 'desc' }]
        }).all();

        records = records.map((r) => r.fields)

        return ([libCon.OK, records])

    } catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const buildAirtableFormLink = (baseLink, parameters) => {
    if (Object.keys(parameters).length === 0)
        return baseLink

    baseLink += "?"

    Object.keys(parameters).forEach(k => {
        baseLink += `prefill_${k.replace(/ /g, "+")}=${parameters[k].toString().replace(/ /g, "+")}&`
    })

    // Removes trailing &
    baseLink = baseLink.slice(0, -1);

    return baseLink

}
