import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import CustomButton from '../elements/CustomButton';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';


export function ManagerMainApp() {

  const i18n = RefLanguage()

  // Current Page
  const setCurrentPage = RefSetCurrentPage()


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} includeBackButton={false} />

        {/* Bundle Set Up*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("bundles")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_SET_UP_BUNDLE)}>{i18n.t("setUpBundle")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_DEPLOY_BUNDLE)}>{i18n.t("deployBundle")}</CustomButton>
        <div className={"horizontalLine"} />


        {/* Data Collection */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("data")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_COLLECT_DATA)}>{i18n.t("collectData")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_BUNDLE_STATUS)}>{i18n.t("checkBundleStatus")}</CustomButton>
        <div className={"horizontalLine"} />

        {/* Extras */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("extras")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_TOOLS_MAIN)}>{i18n.t("toolsSetUp")}</CustomButton>

      </div>
    </div>
  );
}

export default ManagerMainApp


// const styles = {
// };
