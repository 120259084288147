import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { RefLanguage } from '../hooks/LanguageHooks'
import CustomText from './CustomText'
import * as locCon from "../LocalConstants"
import * as locGenFun from "../utils/generalFunctions"
import { CaretDownOutlined } from '@ant-design/icons'
import { StateATOField, StateATOInsideSetField, StateStoredValue } from '../hooks/StoreHooks'
import CustomButton from './CustomButton'




const OptionsModal = ({ isVisible, setIsVisible, selectFunction, options, optionsTextDict }) => {

    const i18n = RefLanguage()

    return (<Modal
        style={{ right: 15 }}
        open={isVisible}
        title={i18n.t("options")}
        onCancel={() => setIsVisible(false)}
        footer={<></>}>
        <div className='verticalSection'>
            {
                options.map((id, i) => <Button style={{ width: "100%", marginTop: 7, marginBottom: 7, height: 45 }} onClick={() => selectFunction(id)}>
                    {optionsTextDict[id]}
                </Button>)
            }
        </div>
    </Modal>)

}




export function SimpleStoreCopySelectionField({ title, value, setValue, options, optionsTextDict }) {

    const [isVisible, setIsVisible] = useState(() => false)

    const i18n = RefLanguage()

    const [answered, setAnswered] = useState(() => true)


    useEffect(() => {
        setAnswered(value in optionsTextDict)
    }, [value, optionsTextDict])





    return (
        <div className='verticalSection'>
            {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            <OptionsModal isVisible={isVisible} setIsVisible={setIsVisible} selectFunction={(id) => { setValue(id); setIsVisible(false) }} options={options} optionsTextDict={optionsTextDict} />
            <div className='horizontalSectionCenter'>
                <CustomButton type={answered ? locCon.SAVE_EDIT_BUTTON : locCon.SAVE_EDIT_BUTTON_DISABLED} onClick={() => answered ? locGenFun.copyToClipboard(value, `Value copied to clipboard`) : false}>{i18n.t("copy")}</CustomButton>
                <div className="horizontalSection" onClick={() => setIsVisible(true)} style={{ height: 40, width: "100%", backgroundColor: "var(--background-color-2)", borderRadius: "25px", marginRight: "2%" }}>
                    <div style={{ width: "90%", textAlign: 'center' }}>
                        <CustomText type={locCon.ELEMENT_TEXT}>{value in optionsTextDict ? optionsTextDict[value] : i18n.t("selectOptions")}</CustomText>
                    </div>
                    <CaretDownOutlined style={{ color: "black", fontSize: 25, marginRight: 10 }} />
                </div >
            </div>
        </div>

    )
}

export function SimpleStoreCopySelectionFieldFromATO({ atoId, fieldId, ...props }) {

    const [value, setValue] = StateATOField(atoId, fieldId)

    return (
        <SimpleStoreCopySelectionField value={value} setValue={setValue} {...props} />);
}

export function SimpleStoreCopySelectionFieldFromATOInsideSet({ positionId, atoId, fieldId, ...props }) {

    const [value, setValue] = StateATOInsideSetField(positionId, atoId, fieldId)

    return (
        <SimpleStoreCopySelectionField value={value} setValue={setValue} {...props} />);
}

export function SimpleStoreCopySelectionFieldFromId({ valueID, ...props }) {

    const [value, setValue] = StateStoredValue(valueID)

    return (
        <SimpleStoreCopySelectionField value={value} setValue={setValue} {...props} />
    );
}