// Type of actions

export const SET_VALUE_IN_STORAGE = "SET_VALUE_IN_STORAGE"
export const SET_MULTIPLE_VALUES_IN_STORAGE = "SET_MULTIPLE_VALUES_IN_STORAGE"

export const SET_AIRTABLE_OBJECT_FIELD = "SET_AIRTABLE_OBJECT_FIELD"
export const SET_AIRTABLE_OBJECT_INSIDE_SET_FIELD = "SET_AIRTABLE_OBJECT_INSIDE_SET_FIELD"
export const SET_AIRTABLE_OBJECT = "SET_AIRTABLE_OBJECT"
export const SET_AIRTABLE_OBJECT_INSIDE_SET = "SET_AIRTABLE_OBJECT_INSIDE_SET"
export const ADD_NEW_POSITION_TO_ATO_SET = "ADD_NEW_POSITION_TO_ATO_SET"
export const DELETE_POSITION_TO_ATO_SET = "DELETE_POSITION_TO_ATO_SET"
export const RESTART_BUNDLE_SET_UP = "RESTART_BUNDLE_SET_UP"
export const RESTART_BUNDLE_DEPLOYMENT = "RESTART_BUNDLE_DEPLOYMENT"
export const UPDATE_RECEIVED_FILES_FOR_SENSOR = "UPDATE_RECEIVED_FILES_FOR_SENSOR"






export const ADD_DEVICE_TO_BUNDLE = "ADD_DEVICE_TO_BUNDLE"
export const REMOVE_DEVICE_FROM_BUNDLE = "REMOVE_DEVICE_FROM_BUNDLE"
export const UPDATE_DEVICE_FROM_BUNDLE = "UPDATE_DEVICE_FROM_BUNDLE"
export const RESTART_SET_UP_PROCESS = "RESTART_SET_UP_PROCESS"
export const RESTART_DEPLOYMENT_PROCESS = "RESTART_DEPLOYMENT_PROCESS"
export const RESTART_DATA_COLLECTION_PROCESS = "RESTART_DATA_COLLECTION_PROCESS"
export const RESTART_HEALTH_FORM = "RESTART_HEALTH_FORM"
export const RESTART_GOVEE_SET_UP = "RESTART_GOVEE_SET_UP"
export const RESTART_KESTREL_SET_UP = "RESTART_KESTREL_SET_UP"
export const RESTART_SET_UP_SENSOR_TOOL_CHECKBOXES = "RESTART_SET_UP_SENSOR_TOOL_CHECKBOXES"
export const SET_VALUE_IN_STORAGE_NO_AIRTABLE_SYNCH_CHECK = "SET_VALUE_IN_STORAGE_NO_AIRTABLE_SYNCH_CHECK"
export const UPDATE_DICTIONARY = "UPDATE_DICTIONARY"






