import React, { useState } from 'react'
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { addNewPositionToATOSet, deletePositionToATOSet, RefATOInsideSetField, RefKeysOfATOSet, setStoredValue } from '../hooks/StoreHooks';
import { RefLanguage } from '../hooks/LanguageHooks';
import CustomText from '../elements/CustomText';
import { Popconfirm, Table } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { SimpleCopyFieldFromATOInsideSet, SimpleStoreCopyFieldFromATOInsideSet } from '../elements/SimpleStoreCopyField';
import { SyncATOInsideSetButton } from '../elements/SyncATOButton';
import CustomButton, { CustomPrimaryButtonWithDisability } from '../elements/CustomButton';
import { refreshSensorStatus, RefReceivedSensorFiles, RefSensorStatus } from '../hooks/BundleDeviceHooks';
import CustomSpin from '../elements/CustomSpin';
import { showErrorDialogByCode } from '../utils/dialogFunctions';
import { showNotification } from '../utils/generalFunctions';
import { formatDistanceShort, formatToIndiaTime } from '../community-hats-js-library/utils/dateFunctions';
import { parseISO } from 'date-fns';
import { getDaysStyle, getEndDateStyle } from '../community-hats-js-library/utils/styleFunctions';
import CollectionStatus from '../elements/CollectionStatus';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import CheckboxGroup from '../elements/CheckboxGroup';


function SensorViewer({ positionId, positionNumber }) {

    const i18n = RefLanguage()


    return (

        <div className='verticalSection' style={{ backgroundColor: "var(--background-color-2)", marginTop: 7, marginBottom: 7 }}>
            <div style={{ width: "100%", marginLeft: "10%", marginTop: "2%" }}>
                <Popconfirm
                    placement="rightTop"
                    title={i18n.t("deleteSensor")}
                    description={i18n.t("removeSensorMessage")}
                    okText={i18n.t("Yes")}
                    cancelText={i18n.t("No")}
                    onConfirm={() => deletePositionToATOSet(positionId)}
                >
                    <DeleteFilled style={{ color: "var(--primary-color-2)", fontSize: 25 }} />
                </Popconfirm>
            </div>
            <div>
                <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("sensor") + " " + positionNumber}</CustomText>
            </div>


            <CheckboxGroup mainCheckbox={{
                [libCon.ID]: locCon.getASCheckBoxId(positionId),
                [libCon.TEXT]: i18n.t("addSensorToBundle"),
                [libCon.CONTAINER]: <div>
                    <SimpleStoreCopyFieldFromATOInsideSet title={i18n.t("serial")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_SERIAL} />
                    <SyncATOInsideSetButton positionId={positionId} atoId={locCon.AT_OBJECT_SENSOR} atoBehavior={libCon.ATO_BEHAVIOR_PULL} onClickCleanup={(val) => setStoredValue(locCon.getASCheckBoxId(positionId), val)} />

                    <div className='horizontalSection'>
                        <SimpleCopyFieldFromATOInsideSet title={i18n.t("brand")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_BRAND} enableCopy={false} />
                        <SimpleCopyFieldFromATOInsideSet title={i18n.t("model")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_MODEL} enableCopy={false} />
                    </div>

                </div>
            }} />
        </div>
    )
}

function SensorStatusViewer({ positionId, positionNumber }) {

    const i18n = RefLanguage()


    const receivedFiles = RefReceivedSensorFiles(positionId)
    const serial = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_SERIAL)


    const [status, message] = RefSensorStatus(positionId)


    const [isSynching, setisSynching] = useState(false)


    const refreshRecords = async () => {
        setisSynching(true)
        let response = await refreshSensorStatus(positionId)

        if (response !== libCon.OK)
            showErrorDialogByCode(response)
        else
            showNotification(i18n.t("refreshComplete"))

        setisSynching(false)
    }




    const sensorFilesColumns = [
        {
            title: 'Uploaded',
            dataIndex: libCon.ATF_DATE_UPLOADED,
            key: libCon.ATF_DATE_UPLOADED,
            align: 'center',
            defaultSortOrder: 'descend',
            sorter: (a, b) => parseISO(a[libCon.ATF_DATE_UPLOADED]) - parseISO(b[libCon.ATF_DATE_UPLOADED]),
            render: (val, record) => <p style={{ textAlign: 'center' }}>{formatDistanceShort(val)}</p>
        },
        {
            title: 'Start Date',
            dataIndex: libCon.ATF_START_DATE,
            key: libCon.ATF_START_DATE,
            align: 'center',
            render: (val, record) => <p style={{ textAlign: 'center' }}>{formatToIndiaTime(val)}</p>
        },
        {
            title: 'End Date',
            dataIndex: libCon.ATF_END_DATE,
            key: libCon.ATF_END_DATE,
            align: 'center',
            render: (val, record) => <p style={{ textAlign: 'center', ...getEndDateStyle(val, record) }}>{formatToIndiaTime(val)}</p>
        },
        {
            title: 'Days',
            dataIndex: libCon.ATF_SIZE_IN_DAYS,
            key: libCon.ATF_SIZE_IN_DAYS,
            align: 'center',
            render: (val, record) => <p style={{ textAlign: 'center', ...getDaysStyle(val, record) }}>{val}</p>
        },
    ];


    return (

        <div className='verticalSection' style={{ backgroundColor: "var(--background-color-2)", marginTop: "1vh", marginBottom: "1vh" }}>
            <CustomText type={locCon.ELEMENT_TITLE_TEXT} style={{ marginTop: "1vh" }}>{i18n.t("sensor") + " " + positionNumber}</CustomText>


            <CollectionStatus status={status} message={message} />

            <SimpleCopyFieldFromATOInsideSet atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_SERIAL} />

            <div className='horizontalSection'>
                <SimpleCopyFieldFromATOInsideSet title={i18n.t("brand")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_BRAND} enableCopy={false} />
                <SimpleCopyFieldFromATOInsideSet title={i18n.t("model")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_MODEL} enableCopy={false} />
            </div>
            {
                isSynching
                    ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                    : <CustomPrimaryButtonWithDisability isDisabled={isNullOrUndefined(serial)} title={i18n.t("incompleteDeployment")} description={i18n.t("addSerial")} onClick={() => refreshRecords()}>{i18n.t("refresh")}</CustomPrimaryButtonWithDisability>
            }


            <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("latestFiles")}</CustomText>

            <div style={{ width: "100%", marginTop: "3vh" }}>

                {
                    receivedFiles.length === 0
                        ? <div className='verticalSection'><CustomText type={locCon.DANGER_TEXT}>{i18n.t("noFilesReceived")}</CustomText></div>
                        : <Table rootClassName="sensorTable"
                            dataSource={receivedFiles}
                            columns={sensorFilesColumns}
                            size="small"
                            pagination={{ pageSize: 4 }} />

                }

            </div>


        </div>

    )
}


export function SensorSetViewer() {


    const keys = RefKeysOfATOSet()

    const i18n = RefLanguage()
    return (
        <div className='verticalSection'>
            <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => addNewPositionToATOSet()}>{i18n.t("addSensorToBundle")}</CustomButton>
            {keys.length === 0 ? <CustomText type={locCon.DANGER_TEXT}>{i18n.t("pleaseAddSensors")}</CustomText> : <div></div>}
            {
                keys.map((k, i) => <SensorViewer key={k} positionId={k} positionNumber={i + 1} />)
            }

        </div>
    )
}


export function SensorStatusSetViewer() {


    const keys = RefKeysOfATOSet()

    return (
        <div className='verticalSection'>
            {
                keys.map((k, i) => <SensorStatusViewer key={k} positionId={k} positionNumber={i + 1} />)
            }
        </div>
    )
}


