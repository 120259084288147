import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import * as types from "./types"
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import storage from 'redux-persist/lib/storage'
import { isNullOrUndefined, isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions'
import { getNewStatus } from '../utils/generalFunctions'



// redux/reducers/countReducer.js
const initialState = {
  // Languages
  [locCon.STORAGE_CURRENT_LOCAL]: libCon.EN,

  // Airtable Objects
  [locCon.AT_OBJECT_EMAIL_ACCOUNT]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },
  [locCon.AT_OBJECT_PHONE]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },
  [locCon.AT_OBJECT_HOUSE]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },
  [locCon.AT_OBJECT_PARTICIPANT]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },
  [locCon.AT_OBJECT_WEARABLE]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },
  [locCon.AT_OBJECT_BUNDLE]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },

  // Placements
  [locCon.AT_OBJECT_PARTICIPANT_PLACEMENT]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },
  [locCon.AT_OBJECT_WEARABLE_PLACEMENT]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },
  [locCon.AT_OBJECT_PHONE_PLACEMENT]: { [libCon.STATUS]: locCon.ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} },

  // Bundle Components
  [locCon.AT_SET_OF_OBJECTS]: {},


  // Sensor Files
  [locCon.AT_RECEIVED_SENSOR_FILES]: {}
};

const rootReducer = (state = initialState, action) => {


  let val, key, atoId, newStatus, fields, id, fieldName, positionId, insideSetState, varMapping, sourceFieldValue, serial

  switch (action.type) {
    case types.SET_VALUE_IN_STORAGE:
      val = action.payload[libCon.VALUE]
      key = action.payload[libCon.KEY]

      state = {
        ...state,
        [key]: val,
      };

      break


    case types.SET_MULTIPLE_VALUES_IN_STORAGE:

      let finalDict = {}
      Object.keys(action.payload).forEach(k => finalDict[k] = action.payload[k])

      state = {
        ...state,
        ...finalDict,
      };

      break



    case types.SET_AIRTABLE_OBJECT_FIELD:

      val = action.payload[libCon.VALUE]
      key = action.payload[libCon.KEY]
      atoId = action.payload[libCon.ID]

      // Only if value changes
      if (state[atoId][libCon.FIELDS][key] !== val)
        newStatus = getNewStatus(state[atoId][libCon.STATUS])


      state = {
        ...state,
        [atoId]: {
          ...state[atoId],
          [libCon.STATUS]: newStatus,
          [libCon.FIELDS]: { ...state[atoId][libCon.FIELDS], [key]: val }
        }
      };


      break

    case types.SET_AIRTABLE_OBJECT_INSIDE_SET_FIELD:

      val = action.payload[libCon.VALUE]
      key = action.payload[libCon.KEY]
      atoId = action.payload[libCon.ID]
      positionId = action.payload[libCon.POSITION_ID]


      insideSetState = state[locCon.AT_SET_OF_OBJECTS][positionId]

      // Only if value changes
      if (insideSetState[atoId][libCon.FIELDS][key] !== val)
        newStatus = getNewStatus(insideSetState[atoId][libCon.STATUS])

      insideSetState = {
        ...insideSetState,
        [atoId]: {
          ...insideSetState[atoId],
          [libCon.STATUS]: newStatus,
          [libCon.FIELDS]: { ...insideSetState[atoId][libCon.FIELDS], [key]: val }
        }
      }

      state = {
        ...state,
        [locCon.AT_SET_OF_OBJECTS]: {
          ...state[locCon.AT_SET_OF_OBJECTS],
          [positionId]: { ...insideSetState }
        }

      };

      break

    case types.SET_AIRTABLE_OBJECT:

      fields = action.payload[libCon.FIELDS]
      id = action.payload[libCon.ID]
      atoId = action.payload[libCon.KEY]

      // Checks for dependencies
      Object.keys(locCon.AT_OBJECTS_CONFIGS).forEach(k => {


        if (atoId in locCon.AT_OBJECTS_CONFIGS[k][libCon.SINGLE_DEPENDENCIES]) {
          varMapping = locCon.AT_OBJECTS_CONFIGS[k][libCon.SINGLE_DEPENDENCIES][atoId]

          // Inside Set
          // Overwrites all
          if (locCon.AT_OBJECTS_CONFIGS[k][libCon.IS_INSIDE_SET]) {
            Object.keys(state[locCon.AT_SET_OF_OBJECTS]).forEach(kInsideState => {

              insideSetState = { ...state[locCon.AT_SET_OF_OBJECTS][kInsideState] }

              // Checks if ATO Exists
              if (!(k in insideSetState))
                insideSetState[k] = newATOObject(k, state, kInsideState)

              // First Checks for id
              // --------------------
              if (libCon.ID in varMapping) {

                // Ids are always stored inside an array
                fieldName = varMapping[libCon.ID]

                // Check if field exists
                if (!(fieldName in insideSetState[k][libCon.FIELDS]))
                  insideSetState[k] = { ...insideSetState[k], [libCon.FIELDS]: { ...insideSetState[k][libCon.FIELDS], [fieldName]: [] } }


                if (insideSetState[k][libCon.FIELDS][fieldName].length === 0 || insideSetState[k][libCon.FIELDS][fieldName][0] !== id)
                  insideSetState[k] = {
                    ...insideSetState[k],
                    [libCon.STATUS]: getNewStatus(insideSetState[k][libCon.STATUS]),
                    [libCon.FIELDS]: { ...insideSetState[k][libCon.FIELDS], [fieldName]: [id] }
                  }
              }
              // Then checks for fields
              // ----------------------
              if (libCon.FIELDS in varMapping) {
                Object.keys(varMapping[libCon.FIELDS]).forEach(sourceFieldName => {
                  fieldName = varMapping[libCon.FIELDS][sourceFieldName]
                  sourceFieldValue = fields[sourceFieldName]

                  // Check if field exists
                  if (!(fieldName in insideSetState[k][libCon.FIELDS]))
                    insideSetState[k] = { ...insideSetState[k], [libCon.FIELDS]: { ...insideSetState[k][libCon.FIELDS], [fieldName]: sourceFieldValue } }
                  else if (insideSetState[k][libCon.FIELDS][fieldName] !== sourceFieldValue)
                    insideSetState[k] = {
                      ...insideSetState[k],
                      [libCon.STATUS]: getNewStatus(insideSetState[k][libCon.STATUS]),
                      [libCon.FIELDS]: { ...insideSetState[k][libCon.FIELDS], [fieldName]: sourceFieldValue }
                    }


                })

              }


              state = {
                ...state,
                [locCon.AT_SET_OF_OBJECTS]: {
                  ...state[locCon.AT_SET_OF_OBJECTS],
                  [kInsideState]: { ...insideSetState }
                }
              }
            })
          }
          // Outside Set
          else {
            // Checks if Exists
            if (!(k in state))
              state = { ...state, [k]: newATOObject(k, state) }


            // First Checks for id
            // --------------------
            if (libCon.ID in varMapping) {

              // Ids are always stored inside an array
              fieldName = varMapping[libCon.ID]

              // Check if field exists
              if (!(fieldName in state[k][libCon.FIELDS]))
                state = {
                  ...state,
                  [k]: { ...state[k], [libCon.FIELDS]: { ...state[k][libCon.FIELDS], [fieldName]: [] } }
                }


              if (state[k][libCon.FIELDS][fieldName].length === 0 || state[k][libCon.FIELDS][fieldName][0] !== id)
                state = {
                  ...state,
                  [k]: {
                    ...state[k],
                    [libCon.STATUS]: getNewStatus(state[k][libCon.STATUS]),
                    [libCon.FIELDS]: { ...state[k][libCon.FIELDS], [fieldName]: [id] }
                  }
                }
            }


            // Then checks for fields
            // ----------------------
            if (libCon.FIELDS in varMapping) {
              Object.keys(varMapping[libCon.FIELDS]).forEach(sourceFieldName => {
                fieldName = varMapping[libCon.FIELDS][sourceFieldName]
                sourceFieldValue = fields[sourceFieldName]

                // Check if field exists
                if (!(fieldName in state[k][libCon.FIELDS]))
                  state = { ...state, [k]: { ...state[k], [libCon.FIELDS]: { ...state[k][libCon.FIELDS], [fieldName]: sourceFieldValue } } }
                else if (state[k][libCon.FIELDS][fieldName] !== sourceFieldValue)
                  state = {
                    ...state, [k]: {
                      ...state[k],
                      [libCon.STATUS]: getNewStatus(state[k][libCon.STATUS]),
                      [libCon.FIELDS]: { ...state[k][libCon.FIELDS], [fieldName]: sourceFieldValue }
                    }
                  }


              })

            }

          }
        }

      })


      // Now checks its own dependencies

      // Single
      varMapping = locCon.AT_OBJECTS_CONFIGS[atoId][libCon.SINGLE_DEPENDENCIES]

      Object.keys(varMapping).forEach(k => {

        // All single dependencies should be outside the set
        if (libCon.ID in varMapping[k] && !isNullOrUndefinedOrEmpty(state[k][libCon.ID])) {
          fields[varMapping[k][libCon.ID]] = [state[k][libCon.ID]]
        }

        if (libCon.FIELDS in varMapping[k]) {
          Object.keys(varMapping[k][libCon.FIELDS]).forEach(originFieldName => {
            if (originFieldName in state[k][libCon.FIELDS])
              fields[varMapping[k][libCon.FIELDS][originFieldName]] = state[k][libCon.FIELDS][originFieldName]
          })
        }
      })

      // Multiple
      varMapping = locCon.AT_OBJECTS_CONFIGS[atoId][libCon.SET_DEPENDENCIES]

      Object.keys(varMapping).forEach(k => {

        // All single dependencies should be outside the set
        // Adds them directly as an array
        if (libCon.ID in varMapping[k] && Object.keys(state[locCon.AT_SET_OF_OBJECTS]).length > 0) {
          fields[varMapping[k][libCon.ID]] = Object.values(state[locCon.AT_SET_OF_OBJECTS]).map(insideSetState => insideSetState[k][libCon.ID])
        }

        // No support for fields
      })


      // Overrides
      state = {
        ...state,
        [atoId]: {
          ...state[atoId],
          [libCon.STATUS]: locCon.ATO_UP_TO_DATE,
          [libCon.ID]: id,
          [libCon.FIELDS]: { ...fields }
        }
      };

      break

    case types.SET_AIRTABLE_OBJECT_INSIDE_SET:

      positionId = action.payload[libCon.POSITION_ID]
      fields = action.payload[libCon.FIELDS]
      id = action.payload[libCon.ID]
      atoId = action.payload[libCon.KEY]

      // Checks for dependencies
      Object.keys(locCon.AT_OBJECTS_CONFIGS).forEach(k => {


        if (atoId in locCon.AT_OBJECTS_CONFIGS[k][libCon.SINGLE_DEPENDENCIES]) {

          varMapping = locCon.AT_OBJECTS_CONFIGS[k][libCon.SINGLE_DEPENDENCIES][atoId]


          // Inside Set
          // Only updates the objects inside its position
          if (locCon.AT_OBJECTS_CONFIGS[k][libCon.IS_INSIDE_SET]) {

            insideSetState = { ...state[locCon.AT_SET_OF_OBJECTS][positionId] }


            // Checks if ATO Exists
            if (!(k in insideSetState))
              insideSetState[k] = newATOObject(k, state, positionId)

            // First Checks for id
            // --------------------
            if (libCon.ID in varMapping) {

              // Ids are always stored inside an array
              fieldName = varMapping[libCon.ID]

              // Check if field exists
              if (!(fieldName in insideSetState[k][libCon.FIELDS]))
                insideSetState[k] = { ...insideSetState[k], [libCon.FIELDS]: { ...insideSetState[k][libCon.FIELDS], [fieldName]: [] } }


              if (insideSetState[k][libCon.FIELDS][fieldName].length === 0 || insideSetState[k][libCon.FIELDS][fieldName][0] !== id)
                insideSetState[k] = {
                  ...insideSetState[k],
                  [libCon.STATUS]: getNewStatus(insideSetState[k][libCon.STATUS]),
                  [libCon.FIELDS]: { ...insideSetState[k][libCon.FIELDS], [fieldName]: [id] }
                }
            }
            // Then checks for fields
            // ----------------------
            if (libCon.FIELDS in varMapping) {
              Object.keys(varMapping[libCon.FIELDS]).forEach(sourceFieldName => {
                fieldName = varMapping[libCon.FIELDS][sourceFieldName]
                sourceFieldValue = fields[sourceFieldName]

                // Check if field exists
                if (!(fieldName in insideSetState[k][libCon.FIELDS]))
                  insideSetState[k] = { ...insideSetState[k], [libCon.FIELDS]: { ...insideSetState[k][libCon.FIELDS], [fieldName]: sourceFieldValue } }
                else if (insideSetState[k][libCon.FIELDS][fieldName] !== sourceFieldValue)
                  insideSetState[k] = {
                    ...insideSetState[k],
                    [libCon.STATUS]: getNewStatus(insideSetState[k][libCon.STATUS]),
                    [libCon.FIELDS]: { ...insideSetState[k][libCon.FIELDS], [fieldName]: sourceFieldValue }
                  }


              })

            }


            state = {
              ...state,
              [locCon.AT_SET_OF_OBJECTS]: {
                ...state[locCon.AT_SET_OF_OBJECTS],
                [positionId]: { ...insideSetState }
              }
            }

          }

        }

        // Set Dependencies
        if (atoId in locCon.AT_OBJECTS_CONFIGS[k][libCon.SET_DEPENDENCIES]) {
          varMapping = locCon.AT_OBJECTS_CONFIGS[k][libCon.SET_DEPENDENCIES][atoId]


          // Checks if Exists
          if (!(k in state))
            state = {
              ...state,
              [k]: newATOObject(k, state)
            }

          // Checks for id
          // --------------------
          if (libCon.ID in varMapping) {

            fieldName = varMapping[libCon.ID]

            if (isNullOrUndefined(state[k][libCon.FIELDS][fieldName]))
              state = {
                ...state,
                [k]: {
                  ...state[k],
                  [libCon.FIELDS]: { ...state[k][libCon.FIELDS], [fieldName]: [] }
                }
              }


            if (!state[k][libCon.FIELDS][fieldName].includes(id))
              state = {
                ...state, [k]: {
                  ...state[k],
                  [libCon.STATUS]: getNewStatus(state[k][libCon.STATUS]),
                  [libCon.FIELDS]: { ...state[k][libCon.FIELDS], [fieldName]: [...state[k][libCon.FIELDS][fieldName], id] }
                }
              }

          }

          // No support for fields inside a set
          // Se description in constants above configuration


        }

      })


      // Now checks its own dependencies

      // Single
      varMapping = locCon.AT_OBJECTS_CONFIGS[atoId][libCon.SINGLE_DEPENDENCIES]

      Object.keys(varMapping).forEach(k => {

        // Checks if Inside set
        insideSetState = state
        if (locCon.AT_OBJECTS_CONFIGS[k][libCon.IS_INSIDE_SET])
          insideSetState = state[locCon.AT_SET_OF_OBJECTS][positionId]


        if (!isNullOrUndefinedOrEmpty(insideSetState) && libCon.ID in varMapping[k] && !isNullOrUndefinedOrEmpty(insideSetState[k][libCon.ID])) {
          fields[varMapping[k][libCon.ID]] = [insideSetState[k][libCon.ID]]
        }

        if (!isNullOrUndefinedOrEmpty(insideSetState) && libCon.FIELDS in varMapping[k]) {
          Object.keys(varMapping[k][libCon.FIELDS]).forEach(originFieldName => {
            if (originFieldName in insideSetState[k][libCon.FIELDS])
              fields[varMapping[k][libCon.FIELDS][originFieldName]] = insideSetState[k][libCon.FIELDS][originFieldName]
          })
        }
      })

      // Multiple
      // No support for multiple dependencies inside set


      // Overrides
      state = {
        ...state,
        [locCon.AT_SET_OF_OBJECTS]: {
          ...state[locCon.AT_SET_OF_OBJECTS],
          [positionId]: {
            ...state[locCon.AT_SET_OF_OBJECTS][positionId],
            [atoId]: {
              ...state[locCon.AT_SET_OF_OBJECTS][positionId][atoId],
              [libCon.STATUS]: locCon.ATO_UP_TO_DATE,
              [libCon.ID]: id,
              [libCon.FIELDS]: { ...fields }
            }
          }

        }

      }

      break


    case types.ADD_NEW_POSITION_TO_ATO_SET:

      positionId = action.payload[libCon.POSITION_ID]

      // Overrides
      state = {
        ...state,
        [locCon.AT_SET_OF_OBJECTS]: {
          ...state[locCon.AT_SET_OF_OBJECTS],
          [positionId]: {
            [locCon.AT_OBJECT_SENSOR]: newATOObject(locCon.AT_OBJECT_SENSOR, state, positionId),
            [locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT]: newATOObject(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, state, positionId)

          }
        }
      }

      break


    case types.DELETE_POSITION_TO_ATO_SET:

      positionId = action.payload[libCon.POSITION_ID]

      // Iterates over the elements it will delete
      Object.keys(state[[locCon.AT_SET_OF_OBJECTS]][positionId]).forEach(atoId => {

        id = state[[locCon.AT_SET_OF_OBJECTS]][positionId][atoId][libCon.ID]

        // Checks for dependencies
        Object.keys(locCon.AT_OBJECTS_CONFIGS).forEach(k => {

          // Set Dependencies
          if (atoId in locCon.AT_OBJECTS_CONFIGS[k][libCon.SET_DEPENDENCIES]) {
            fieldName = locCon.AT_OBJECTS_CONFIGS[k][libCon.SET_DEPENDENCIES][atoId]

            // Checks if Exists
            if (!(k in state))
              state = { ...state, [k]: newATOObject(k, state) }

            if (isNullOrUndefined(state[k][libCon.FIELDS][fieldName]))
              state = {
                ...state,
                [k]: {
                  ...state[k],
                  [libCon.FIELDS]: { ...state[k][libCon.FIELDS], [fieldName]: [] }
                }
              }

            if (state[k][libCon.FIELDS][fieldName].includes(id))
              state = {
                ...state, [k]: {
                  ...state[k],
                  [libCon.STATUS]: getNewStatus(state[k][libCon.STATUS]),
                  [libCon.FIELDS]: { ...state[k][libCon.FIELDS], [fieldName]: [...state[k][libCon.FIELDS][fieldName].filter(val => val !== id)] }
                }
              }
          }

        })


      })

      serial = null
      if (locCon.AT_OBJECT_SENSOR in state[locCon.AT_SET_OF_OBJECTS][positionId] &&
        libCon.FIELDS in state[locCon.AT_SET_OF_OBJECTS][positionId][locCon.AT_OBJECT_SENSOR] &&
        libCon.ATF_SERIAL in state[locCon.AT_SET_OF_OBJECTS][positionId][locCon.AT_OBJECT_SENSOR][libCon.FIELDS])
        serial = state[locCon.AT_SET_OF_OBJECTS][positionId][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL]

      // Removes also from Received Sensor Files
      if (!isNullOrUndefined(serial)) {
        let { [serial]: ___, ...remainingSenosors } = state[locCon.AT_RECEIVED_SENSOR_FILES]

        state = {
          ...state,
          [locCon.AT_RECEIVED_SENSOR_FILES]: {
            ...remainingSenosors
          }
        }

      }


      // Removes position
      let { [positionId]: __, ...remaining } = state[locCon.AT_SET_OF_OBJECTS]

      // TODO
      // Remove the sensor placement checkbox from the store


      // Overrides
      state = {
        ...state,
        [locCon.AT_SET_OF_OBJECTS]: {
          ...remaining
        }
      }

      break

    case types.RESTART_BUNDLE_SET_UP:

      state = { ...initialState, [locCon.STORAGE_CURRENT_LOCAL]: state[locCon.STORAGE_CURRENT_LOCAL] }
      break

    case types.RESTART_BUNDLE_DEPLOYMENT:

      // CheckBoxes
      state = {
        ...state,
      }
      locCon.BUNDLE_DEPLOYMENT_CHECKBOXES.forEach(k => state[k] = false)

      // TODO
      // RESTART PLACEMENTS


      break

    case types.UPDATE_RECEIVED_FILES_FOR_SENSOR:

      val = action.payload[libCon.VALUE]
      key = action.payload[libCon.KEY]

      state = {
        ...state,
        [locCon.AT_RECEIVED_SENSOR_FILES]: {
          ...state[locCon.AT_RECEIVED_SENSOR_FILES],
          [key]: val
        }
      }

      break


    default:
      break;
  }

  return state
};


const persistConfig = {
  key: 'root',
  storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: true, immutableCheck: true }),
  reducer: persistedReducer
})

export const persistor = persistStore(store)



// Support functions

const newATOObject = (atoId, state, positionId = null) => {

  let placements = [locCon.AT_OBJECT_PHONE_PLACEMENT, locCon.AT_OBJECT_WEARABLE_PLACEMENT, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT]

  let atoOb = { ...locCon.AT_OBJECT_BASE_STRUCTURE }

  let fields = atoOb[libCon.FIELDS]

  let tagColor, tagCode = null
  if (locCon.AT_OBJECT_BUNDLE in state &&
    [libCon.FIELDS] in state[locCon.AT_OBJECT_BUNDLE] &&
    libCon.ATF_TAG_CODE in state[locCon.AT_OBJECT_BUNDLE][libCon.FIELDS]) {
    tagCode = state[locCon.AT_OBJECT_BUNDLE][libCon.FIELDS][libCon.ATF_TAG_CODE]
    tagColor = state[locCon.AT_OBJECT_BUNDLE][libCon.FIELDS][libCon.ATF_TAG_COLOR]
  }


  if (placements.includes(atoId) && !isNullOrUndefined(tagColor) && !isNullOrUndefined(tagCode)) {
    fields[libCon.ATF_TAG_CODE] = tagCode
    fields[libCon.ATF_TAG_COLOR] = tagColor

  }


  // Now uses the configuration dictionary to fill the available values

  // Single
  let isInsideSet = locCon.AT_OBJECTS_CONFIGS[atoId][libCon.IS_INSIDE_SET]
  let varMapping = locCon.AT_OBJECTS_CONFIGS[atoId][libCon.SINGLE_DEPENDENCIES]

  // Outside set
  if (!isInsideSet) {

    Object.keys(varMapping).forEach(k => {

      // All single dependencies should be outside the set
      if (libCon.ID in varMapping[k] && !isNullOrUndefinedOrEmpty(state[k][libCon.ID])) {
        fields[varMapping[k][libCon.ID]] = [state[k][libCon.ID]]
      }

      if (libCon.FIELDS in varMapping[k]) {
        Object.keys(varMapping[k][libCon.FIELDS]).forEach(originFieldName => {
          if (originFieldName in state[k][libCon.FIELDS])
            fields[varMapping[k][libCon.FIELDS][originFieldName]] = state[k][libCon.FIELDS][originFieldName]
        })
      }
    })

    // Multiple
    varMapping = locCon.AT_OBJECTS_CONFIGS[atoId][libCon.SET_DEPENDENCIES]

    Object.keys(varMapping).forEach(k => {

      // All single dependencies should be outside the set
      // Adds them directly as an array
      if (libCon.ID in varMapping[k] && Object.keys(state[locCon.AT_SET_OF_OBJECTS]).length > 0) {
        fields[varMapping[k][libCon.ID]] = Object.values(state[locCon.AT_SET_OF_OBJECTS]).map(insideSetState => insideSetState[k][libCon.ID])
      }

      // No support for fields
    })

  }
  // Inside Set
  else {


    Object.keys(varMapping).forEach(k => {

      // Checks if Inside set
      let insideSetState = state
      if (locCon.AT_OBJECTS_CONFIGS[k][libCon.IS_INSIDE_SET])
        insideSetState = state[locCon.AT_SET_OF_OBJECTS][positionId]


      if (!isNullOrUndefinedOrEmpty(insideSetState) && libCon.ID in varMapping[k] && !isNullOrUndefinedOrEmpty(insideSetState[k][libCon.ID])) {
        fields[varMapping[k][libCon.ID]] = [insideSetState[k][libCon.ID]]
      }

      if (!isNullOrUndefinedOrEmpty(insideSetState) && libCon.FIELDS in varMapping[k]) {
        Object.keys(varMapping[k][libCon.FIELDS]).forEach(originFieldName => {
          if (originFieldName in insideSetState[k][libCon.FIELDS])
            fields[varMapping[k][libCon.FIELDS][originFieldName]] = insideSetState[k][libCon.FIELDS][originFieldName]
        })
      }
    })

    // Multiple
    // No support for multiple dependencies inside set



  }







  return atoOb

}