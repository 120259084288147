import React from 'react'
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import BackButton from '../elements/BackButton';
import { LanguageState } from '../hooks/LanguageHooks';
import { sleep } from '../community-hats-js-library/utils/generalFunctions';
import { setStoredValue } from '../hooks/StoreHooks';
import { Button, Typography } from 'antd';


const { Text } = Typography;

function Header({ long, includeBackButton, includeUpdateDelay }) {

  // Translation
  const [i18n, currentLocale, setCurrentLocale] = LanguageState()

  const localSetCurrentLocale = async (loc) => {
    if (includeUpdateDelay) {

      setStoredValue(locCon.UPDATING_LANGUAGE, true)
      await sleep(200)

      setCurrentLocale(loc)

      setTimeout(() => setStoredValue(locCon.UPDATING_LANGUAGE, false), locCon.LANGUAGE_LOADING_TIME);


    }
    else
      setCurrentLocale(loc)
  }

  return (
    <div className='verticalSection' style={{ paddingTop: "2vw", paddingLeft: "10vw", paddingRight: "10vw" }}>
      {libCon.DEVELOPMENT ? <Text style={{ color: "green", }}>DEVELOPMENT</Text> : <div></div>}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        {includeBackButton === true ? <BackButton /> : <div></div>}

        <div style={styles.languagesContainer}>
          <Button style={currentLocale === "en" ? styles.selectedLanguage : styles.language} onPress={() => localSetCurrentLocale("en")}>
            EN
          </Button>
          <Button style={currentLocale === "es" ? styles.selectedLanguage : styles.language} onPress={() => localSetCurrentLocale("es")}>
            ES
          </Button>
          <Button style={currentLocale === "gu" ? styles.selectedLanguage : styles.language} onPress={() => localSetCurrentLocale("gu")}>
            ગુ
          </Button>
        </div>
      </div>
      <div className="verticalSection" >
        <Text style={styles.mainTitle}>{i18n.t("mainTitle")}</Text>
        {long === true ? <Text style={styles.subTitle}>{i18n.t("mainSubtitle")}</Text> : <div></div>}
      </div>
      <div className='horizontalLine' style={{ marginTop: 0, marginBottom: 5 }} />
    </div>
  )
}

export default Header

Header.defaultProps = {
  long: true,
  includeBackButton: true,
  includeUpdateDelay: false
};


const styles = {
  titleContainer: {
    marginBottom: 15,
    backgroundColor: "var(--primary-color1)"
  },
  mainTitle:
  {
    fontSize: 32,
    textAlign: "center",
    color: "var(--primary-color2)",
    fontWeight: 'bold',
    width: "100%"
  },
  subTitle:
  {
    fontSize: 18,
    textAlign: "center",
    color: "#800202",
    width: "100%"
  },
  languagesContainer: {
    display: "flex",
    width: "50%",
    flexDirection: 'row-reverse',
    alignItems: 'stretch',
  },
  selectedLanguage: {
    borderWidth: 1,
    borderColor: "var(--primary-color5)",
    padding: 5,
    marginLeft: 4,
    marginRight: 4,
  },
  language: {
    borderWidth: 1,
    borderColor: "var(--primary-color1)",
    padding: 5,
    marginLeft: 4,
    marginRight: 4
  },
};



