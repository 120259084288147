import React from "react";
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import * as locGenFun from "../utils/generalFunctions"
import all_names from './../data/names.json';
import { setATOField, setStoredValue, StateATOField, StateStoredValue } from "../hooks/StoreHooks";
import { RefLanguage } from "../hooks/LanguageHooks";
import CustomButton from "../elements/CustomButton";
import CustomText from "../elements/CustomText";
import { Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import { SimpleCopyField, SimpleCopyFieldFromId } from "../elements/SimpleStoreCopyField";
import { ModalHook } from "../hooks/ModalHooks";


// Imports Names and las Names
const NAMES = all_names.names
const LAST_NAMES = all_names.last_names
const WORDS = all_names.words
const EXTRA_SYMBOLS = ["?", "!", ":", "_", "+", "-", "."]


// Number of digits at the end
const NUM_DIGITS_EMAIL = 6


export default function RandomEmailAccountGenerator() {

  const i18n = RefLanguage()

  // Modal
  const [modal, openModal] = ModalHook()

  // Values
  const [firstName, setFirstName] = StateStoredValue(locCon.RAG_FIRST_NAME)
  const [lastName, setLastName] = StateStoredValue(locCon.RAG_LAST_NAME)
  const [email, setEmail] = StateATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_EMAIL)
  const [password, setPassword] = StateATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_PASSWORD)

  const [valueHasBeenCopied, setValueHasBeenCopied] = StateStoredValue(locCon.RAG_HAS_COPIED)
  const [emailHasBeenCopied, setEmailHasBeenCopied] = StateStoredValue(locCon.RAG_HAS_COPIED_EMAIL)


  const generateEmail = (first, last) => {

    const localFun = () => {
      let num = Math.round(Math.random() * (10 ** NUM_DIGITS_EMAIL - 1))
      let r_email = `${first.toLowerCase()}.${last.toLowerCase()}${num.toString().padStart(NUM_DIGITS_EMAIL, '0')}@gmail.com`

      setEmail(r_email)
      setEmailHasBeenCopied(false)

      return r_email

    }

    if (emailHasBeenCopied) {
      openModal(i18n.t("valuesCopied"), i18n.t("emailCopiedLong"), [
        {
          text: i18n.t("cancel"),
          onClick: () => false,
          type: locCon.MODULE_CANCEL_BUTTON,
        },
        {
          text: i18n.t("generate"),
          onClick: () => localFun(),
          type: locCon.MODULE_REGULAR_BUTTON
        },
      ]);
    }
    else
      localFun()


  }

  const generatePassword = () => {


    const r_base = WORDS[Math.floor(Math.random() * WORDS.length)]
    const num = Math.round(Math.random() * (10 ** NUM_DIGITS_EMAIL - 1))
    const r_extra = EXTRA_SYMBOLS[Math.floor(Math.random() * EXTRA_SYMBOLS.length)]

    const r_password = `${r_base}${r_extra}${num.toString().padStart(NUM_DIGITS_EMAIL, '0')}`

    setPassword(r_password)

    return r_password

  }


  const generateValues = () => {

    if (valueHasBeenCopied) {

      openModal(i18n.t("valuesCopied"), i18n.t("valuesCopiedLong"), [
        {
          text: i18n.t("cancel"),
          onClick: () => false,
          type: locCon.MODULE_CANCEL_BUTTON,
        },
        {
          text: i18n.t("generate"),
          onClick: () => generateAllValues(),
          type: locCon.MODULE_REGULAR_BUTTON,
        },
      ])


    }
    else
      generateAllValues()
  }

  const generateAllValues = async () => {

    // First Nam
    const r_first = NAMES[Math.floor(Math.random() * NAMES.length)]
    setFirstName(r_first)

    // Last Name
    const r_last = LAST_NAMES[Math.floor(Math.random() * LAST_NAMES.length)]
    setLastName(r_last)

    // date of birth
    const currentDate = new Date();
    const year = currentDate.getFullYear() - Math.round((23 + Math.random() * 30))

    setStoredValue(locCon.RAG_YEAR_OF_BIRTH, year)
    generateEmail(r_first, r_last, true)
    let generatedPassword = generatePassword()

    // Sets the other passwords


    setATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_DROPBOX_PASSWORD, generatedPassword)
    setATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_GOVEE_PASSWORD, generatedPassword)

    setValueHasBeenCopied(false)

  }


  return (

    <div className="verticalSection">

      {/* Modal */}
      {modal}

      <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("randomEmailGenerator")}</CustomText>
      <CustomButton type={locCon.PRIMARY_BUTTON} onClick={generateValues}>{i18n.t("generate")}</CustomButton>
      <div style={styles.section}>
        <SimpleCopyFieldFromId title={i18n.t("firstName")} valueID={locCon.RAG_FIRST_NAME} />
        <SimpleCopyFieldFromId title={i18n.t("lastName")} valueID={locCon.RAG_LAST_NAME} />
      </div>
      <div style={styles.section}>
        <SimpleCopyFieldFromId title={i18n.t("yearOfBirth")} valueID={locCon.RAG_YEAR_OF_BIRTH} />
        <div style={{ width: "50%" }}></div>


      </div>
      <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("email")}</CustomText>
      <div style={{ ...styles.section, justifyContent: "space-around" }}>
        <CustomText type={locCon.ELEMENT_TEXT} style={{ width: "80%" }}>{email === null || email === undefined ? "----" : `${email}`}</CustomText>
        <RedoOutlined style={{ fontSize: 25, color: "var(--primary-color-5)", marginTop: 2, marginBottom: 4, marginLeft: 5 }} onClick={() => generateEmail(firstName, lastName)} />
      </div>
      <div style={styles.section}>
        <Button style={styles.copyPrefixCompleteButton} onClick={() => { locGenFun.copyToClipboard(email.replace("@gmail.com", ""), i18n.t("emailPrefixCopiedToClipboard")); setEmailHasBeenCopied(true) }}>{i18n.t("copyPrefix")}</Button>
        <Button style={styles.copyPrefixCompleteButton} onClick={() => { locGenFun.copyToClipboard(email, i18n.t("emailCompleteCopiedToClipboard")); setEmailHasBeenCopied(true) }}>{i18n.t("copyComplete")}</Button>
      </div>
      <div style={{ ...styles.section, marginTop: "2vh" }}>
        <SimpleCopyField title={i18n.t("googleAccountPassword")} value={password} onCopyCleanUp={(__) => setValueHasBeenCopied(true)} />
      </div>
    </div>
  );
}




const styles = {

  section: {
    width: "80%",
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    marginBottom: 3

  },
  copyPrefixCompleteButton:
  {
    backgroundColor: 'var(--primary-color-3)',
    borderColor: 'var(--primary-color-3)',
    color: "#ffffff",
    width: "120px",
    marginLeft: "1%",
    marginRight: "1%"
  }

}









