// Modal Hooks

import { Modal } from "antd"
import { useState } from "react"
import CustomButton from "../elements/CustomButton"
import * as locCon from "../LocalConstants"


export const ModalHook = () => {
    const [modalVisible, setModalVisible] = useState(() => false)
    const [modalTitle, setModalTitle] = useState("")
    const [modalFooter, setModalFooter] = useState(() => [])
    const [modalContent, setModalContent] = useState(<div></div>)


    const modal = <Modal open={modalVisible}
        title={modalTitle}
        style={{ right: 15 }}
        onCancel={() => setModalVisible(false)}
        footer={modalFooter.map(ob => <CustomButton
            type={ob[locCon.MODULE_TYPE]}
            onClick={() => { ob[locCon.MODULE_ON_CLICK](); setModalVisible(false) }}>
            {ob[locCon.MODULE_TEXT]}
        </CustomButton>)}
    >
        {modalContent}
    </Modal>

    const openModal = (title, content, footer) => {

        setModalTitle(title)
        setModalContent(content)
        setModalFooter(footer)
        setModalVisible(true)
    }


    return [modal, openModal]

}