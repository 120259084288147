import { useEffect, useState } from 'react'
import * as locCon from "../LocalConstants"

import { I18n } from 'i18n-js';

// Languages
import en from "../languages/en.json"
import es from "../languages/es.json"
import gu from "../languages/gu.json"


import { StateStoredValue, getStoredValue, RefStoredValue } from './StoreHooks';


// Set the key-value pairs for the different languages you want to support.
const baseLanguages = {
  en: en,
  es: es,
  gu: gu
}

const baseI18n = new I18n(baseLanguages);
baseI18n.locale = "en"
baseI18n.enableFallback = true;

export const RefLanguage = () => {

  const currentLocale = RefStoredValue(locCon.STORAGE_CURRENT_LOCAL)
  const [i18n, setI18n] = useState(baseI18n)

  // If locale changes
  useEffect(() => {


    const newI18n = new I18n(baseLanguages);
    newI18n.locale = currentLocale
    newI18n.enableFallback = true;

    setI18n(newI18n)

  }, [currentLocale])


  return i18n

}

export const LanguageState = () => {

  const [currentLocale, setCurrentLocale] = StateStoredValue(locCon.STORAGE_CURRENT_LOCAL)
  const [i18n, setI18n] = useState(baseI18n)

  // If locale changes
  useEffect(() => {

    const newI18n = new I18n(baseLanguages);
    newI18n.locale = currentLocale
    newI18n.enableFallback = true;

    setI18n(newI18n)

  }, [currentLocale])


  return [i18n, currentLocale, setCurrentLocale]

}



export const getCurrentI18n = () => {
  const currentLocale = getStoredValue(locCon.STORAGE_CURRENT_LOCAL)
  const newI18n = new I18n(baseLanguages);
  newI18n.locale = currentLocale
  newI18n.enableFallback = true;

  return (newI18n)
} 