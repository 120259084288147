import React, { useEffect, useState } from 'react'
import { RefATObject, RefATObjectInsideSet, setATObject, setATObjectInsideSet } from '../hooks/StoreHooks'
import { CustomPrimaryButtonWithDisability } from './CustomButton'
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import * as locGenFun from "../utils/generalFunctions"
import { syncRecord } from '../utils/airtableFunctions'
import { RefLanguage } from '../hooks/LanguageHooks'
import CustomSpin from './CustomSpin'
import { filterObjectByKeys, isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions'
import CustomText from './CustomText'
import { RefBundleDevices } from '../hooks/BundleDeviceHooks'

export function SyncATOInsideSetButton({ positionId, atoId, atoBehavior, ...props }) {

    // Object
    const [status, id, fields] = RefATObjectInsideSet(positionId, atoId)


    return (<GenericSyncATOButton status={status}
        id={id}
        fields={fields}
        setObject={(newId, newFields) => setATObjectInsideSet(positionId, atoId, newId, newFields)}
        config={locCon.AT_OBJECTS_CONFIGS[atoId]}
        atoBehavior={atoBehavior} {...props} />)
}

export function SyncATOButton({ atoId, atoBehavior, ...props }) {


    // Object
    const [status, id, fields] = RefATObject(atoId)


    return (<GenericSyncATOButton status={status}
        id={id}
        fields={fields}
        setObject={(newId, newFields) => setATObject(atoId, newId, newFields)}
        config={locCon.AT_OBJECTS_CONFIGS[atoId]}
        atoBehavior={atoBehavior} {...props} />)
}

function GenericSyncATOButton({ status, id, fields, setObject, config, atoBehavior, customMessages = {}, becomeTextOnSuccess = true, onClickCleanup = (__) => true }) {

    // Language
    const i18n = RefLanguage()

    // Button Behavior
    const [isDisabled, setIsDisabled] = useState(false)
    const [buttonText, setButtonText] = useState(() => "---")
    const [successText, setSuccessText] = useState(() => null)
    const [warningText, setWarningText] = useState(() => null)

    const [isSynching, setisSynching] = useState(() => false)

    const bundleDevices = RefBundleDevices()


    const syncATObject = async () => {


        setisSynching(true)
        let includedIds = [...config[libCon.NECESSARY_IDS], ...config[libCon.OPTIONAL_IDS]]

        // Removes derivates
        includedIds = includedIds.filter(k => !config[libCon.DERIVATE_IDS].includes(k))


        let finalFields = filterObjectByKeys(fields, includedIds)
        let searchFields = config[libCon.SEARCH]
        let searchValues = !isNullOrUndefinedOrEmpty(config[libCon.SEARCH]) ? searchFields.map(f => fields[f]) : null
        let [response, message, newId, newFields] = await syncRecord({ tableId: config[libCon.TABLE], behavior: atoBehavior, id: id, fields: finalFields, searchField: searchFields, searchValue: searchValues })

        if (response === libCon.OK) {
            setObject(newId, newFields)
            locGenFun.showNotification(message)
        }
        else if (response in customMessages)
            locGenFun.showError(customMessages[response])
        else
            locGenFun.showError(message)

        onClickCleanup(response === libCon.OK)
        setisSynching(false)

    }

    useEffect(() => {

        switch (atoBehavior) {
            case libCon.ATO_BEHAVIOR_PULL:
                setButtonText(i18n.t("syncRecord"))
                setSuccessText(i18n.t("recordSynched"))
                break
            case libCon.ATO_BEHAVIOR_UPDATE:
                setButtonText(i18n.t("updateRecord"))
                setSuccessText(i18n.t("recordUpdated"))
                break

            case libCon.ATO_BEHAVIOR_CREATE:
                setButtonText(i18n.t("createRecord"))
                setSuccessText(i18n.t("recordInserted"))
                break

            case libCon.ATO_BEHAVIOR_UPDATE_CREATE:
                if (status === locCon.ATO_NEEDS_SYNCH) {
                    setButtonText(i18n.t("updateRecord"))
                    setSuccessText(i18n.t("recordUpdated"))
                }
                else {
                    setButtonText(i18n.t("createRecord"))
                    setSuccessText(i18n.t("recordInserted"))
                }
                break

            case libCon.ATO_BEHAVIOR_PULL_CREATE:

                setButtonText(i18n.t("syncRecord"))
                setSuccessText(i18n.t("recordSynched"))

                break

            default:
                setButtonText(i18n.t("addSyncRecord"))
                break
        }


    }, [atoBehavior, i18n, status])

    useEffect(() => {

        if (status === locCon.ATO_UP_TO_DATE)
            return
        else {
            let sizeOfSet = bundleDevices.length

            let isMissing = config[libCon.NECESSARY_IDS].map(k => {
                let value = fields[k]

                // Is single dependency
                if (Object.values(config[libCon.SINGLE_DEPENDENCIES]).includes(k)) {
                    return (isNullOrUndefinedOrEmpty(value))

                }
                // Set Dependency
                else if (Object.values(config[libCon.SET_DEPENDENCIES]).includes(k)) {
                    return (isNullOrUndefinedOrEmpty(value) || value.length !== sizeOfSet)
                }
                // Other                
                return isNullOrUndefinedOrEmpty(value)

            })

            if (isMissing.some(v => v)) {
                setIsDisabled(true)

                let text = `${i18n.t("missing")}: ` + config[libCon.NECESSARY_IDS].filter((_, i) => isMissing[i]).join(", ")

                setWarningText(text)

            }
            else
                setIsDisabled(false)



        }



    }, [status, atoBehavior, fields, config, i18n, bundleDevices])




    return (
        <div className='verticalSection'>
            {
                isSynching
                    ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                    : status === locCon.ATO_UP_TO_DATE && becomeTextOnSuccess
                        ? <CustomText type={locCon.ELEMENT_TEXT}>{successText}</CustomText>
                        : <CustomPrimaryButtonWithDisability isDisabled={isDisabled} title={i18n.t("missingValues")} description={warningText} onClick={() => syncATObject()}>
                            {buttonText}
                        </CustomPrimaryButtonWithDisability>
            }
        </div>
    )
}

