import { Typography } from 'antd'
import React from 'react'
import * as locCon from "../LocalConstants"
import { isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions'


const globalTextStyles = {
    textAlign: "center",
}

const { Text } = Typography;

function CustomText({ type, ...props }) {

    let { style, ...otherProps } = props
    if (isNullOrUndefinedOrEmpty(style))
        style = {}

    switch (type) {
        case locCon.SECTION_TITLE_TEXT:
            return (<Text style={{
                ...globalTextStyles,
                fontSize: 20,
                fontWeight: 'bold',
                ...style
            }}
                {...otherProps} />)
        case locCon.INSTRUCTIONS_TEXT:
            return (<Text style={{
                ...globalTextStyles,
                fontSize: 18,
                ...style
            }}
                {...otherProps} />)
        case locCon.DANGER_TEXT:
            return (<Text style={{
                ...globalTextStyles,
                color: "var(--primary-color-2)",
                fontSize: 18,
                ...style
            }}
                {...otherProps} />)
        case locCon.ELEMENT_TITLE_TEXT:
            return (<Text style={{
                ...globalTextStyles,
                fontSize: 17,
                fontWeight: 'bold',
                ...style
            }}
                {...otherProps} />)
        case locCon.INFO_TEXT:
            return (<Text style={{
                ...globalTextStyles,
                fontSize: 15,
                ...style
            }}
                {...otherProps} />)
        case locCon.ELEMENT_TEXT:
            return (<Text style={{
                ...globalTextStyles,
                fontSize: 16,
                color: "var(--primary-color-3)",
                ...style
            }}
                {...otherProps} />)

        case locCon.DEFAULT_INFO_TEXT:
            return (<Text style={{
                ...globalTextStyles,
                fontSize: 14,
                ...style
            }}
                {...otherProps} />)

        default:
            return (<Text  {...otherProps} />)
    }


}

export default CustomText