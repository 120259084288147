import React, { useEffect, useState } from "react";
import * as locCon from "../LocalConstants"
import { copyToClipboard, showNotification } from "../utils/generalFunctions";
import * as locGenFun from "../utils/generalFunctions";
import { RefATOField, RefATOInsideSetField, RefStoredValue, StateATOField, StateATOInsideSetField, StateStoredBooleanValue, StateStoredValue } from "../hooks/StoreHooks";
import { RefLanguage } from "../hooks/LanguageHooks";
import { Input } from "antd";
import CustomButton from "./CustomButton";
import CustomText from "./CustomText";
import { ModalHook } from "../hooks/ModalHooks";
import { CheckboxDefaultValue } from "./CustomCheckBox";



export function SimpleCopyField({ title, value, enableCopy, maxLength, onCopyCleanUp }) {

  const i18n = RefLanguage()

  return (
    <div className='verticalSection'>
      {title !== null && title !== undefined ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
      <div style={styles.section}>
        {enableCopy === true ? <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={() => { locGenFun.copyToClipboard(value, `${title} copied to clipboard`); onCopyCleanUp(value) }}>{i18n.t("copy")}</CustomButton> : <div></div>}
        <CustomText type={locCon.ELEMENT_TEXT} >{value === null || value === undefined ? "----" : value.length >= maxLength ? value.substring(0, maxLength) + "..." : value}</CustomText>
        {enableCopy === true ? <div style={{ width: "15%" }}></div> : <div></div>}
      </div>
    </div>
  )
}


SimpleCopyField.defaultProps = {
  maxLength: 40,
  enableCopy: true,
  onCopyCleanUp: (val) => true
};


export function SimpleCopyFieldFromId({ valueID, processFunction, ...props }) {

  const value = RefStoredValue(valueID)

  return (
    <SimpleCopyField {...props} value={processFunction(value)} />
  )
}

SimpleCopyFieldFromId.defaultProps = {
  processFunction: (val) => val,
};

export function SimpleCopyFieldFromATO({ atoId, fieldId, processFunction, ...props }) {

  const value = RefATOField(atoId, fieldId)

  return (
    <SimpleCopyField {...props} value={processFunction(value)} />
  )
}

SimpleCopyFieldFromATO.defaultProps = {
  processFunction: (val) => val,
};


export function SimpleCopyFieldFromATOInsideSet({ positionId, atoId, fieldId, processFunction, ...props }) {

  const value = RefATOInsideSetField(positionId, atoId, fieldId)

  return (
    <SimpleCopyField {...props} value={processFunction(value)} />
  )
}

SimpleCopyFieldFromATOInsideSet.defaultProps = {
  processFunction: (val) => val,
};









export function SimpleStoreCopyFieldFromATO({ atoId, fieldId, ...props }) {

  const [value, setValue] = StateATOField(atoId, fieldId)
  const [editingValue, setEditingValue] = StateStoredBooleanValue(fieldId + locCon.EDITING_VALUE)

  return (
    <SimpleStoreCopyField value={value} setValue={setValue} editingValue={editingValue} setEditingValue={setEditingValue} {...props} />);
}

export function SimpleStoreCopyFieldFromATOInsideSet({ positionId, atoId, fieldId, ...props }) {

  const [value, setValue] = StateATOInsideSetField(positionId, atoId, fieldId)
  const [editingValue, setEditingValue] = StateStoredBooleanValue(fieldId + "_" + positionId + locCon.EDITING_VALUE)

  return (
    <SimpleStoreCopyField value={value} setValue={setValue} editingValue={editingValue} setEditingValue={setEditingValue} {...props} />);
}

export function SimpleStoreCopyFieldFromId({ valueID, ...props }) {

  const [value, setValue] = StateStoredValue(valueID)
  const [editingValue, setEditingValue] = StateStoredBooleanValue(valueID + locCon.EDITING_VALUE)

  return (
    <SimpleStoreCopyField value={value} setValue={setValue} editingValue={editingValue} setEditingValue={setEditingValue} {...props} />
  );
}



export function SimpleStoreCopyField({ value, setValue, editingValue, setEditingValue, title, placeHolder, infoText, checkFunction, checkWarningFunction, maxLength, warningText, isInputNumeric, includeCheckbox, defaultValue, checkboxText, }) {




  const i18n = RefLanguage()

  // Modal
  const [modal, openModal] = ModalHook()

  // Value
  const [localValue, setLocalValue] = useState(() => value)


  const editSaveValue = () => {



    if (editingValue) {
      // Checks the value
      if (checkFunction(localValue)) {
        if (checkWarningFunction(localValue)) {
          setValue(localValue)
          setEditingValue(false)
        }
        else {
          const text = warningText === null ? i18n.t("unlikelyValueLong") : warningText

          openModal(i18n.t("unlikelyValue"), `${text} Value Entered: ${localValue}`, [
            {
              text: i18n.t("cancel"),
              onClick: () => false,
              type: locCon.MODULE_CANCEL_BUTTON,
            },
            {
              text: i18n.t("valueIsCorrect"),
              onClick: () => { setValue(localValue); setEditingValue(false) },
              type: locCon.MODULE_REGULAR_BUTTON
            }
          ]);

        }

      }
      else
        showNotification(i18n.t("enteredValueNotValid"))

    }
    else {
      setEditingValue(true)
      setLocalValue(value)
    }

  }

  const pasteFromClipboard = async () => {

    let copiedValue = await locGenFun.getClipboardValue()
    setLocalValue(copiedValue)

    // Checks the value
    if (checkFunction(copiedValue)) {
      setValue(copiedValue)
      setEditingValue(false)
    }
    else
      showNotification(i18n.t("enteredValueNotValid"))
  }


  useEffect(() => {

    setEditingValue(editingValue || !checkFunction(value))
    setLocalValue(value)

  }, [value, checkFunction, setEditingValue, editingValue])



  return (

    <div className="verticalSection">
      {modal}
      {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
      {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}
      <div style={styles.section}>
        {
          editingValue
            ? <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={() => pasteFromClipboard()}>{i18n.t("paste")}</CustomButton>
            : <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={() => copyToClipboard(value, `${title} copied to clipboard`)}>{i18n.t("copy")}</CustomButton>
        }
        {
          editingValue
            ? <Input textAlign={'center'} keyboardType={isInputNumeric ? "numeric" : "default"} style={styles.input} onChange={(e) => setLocalValue(e.target.value)} value={localValue} placeholder={placeHolder} />
            : <CustomText type={locCon.ELEMENT_TEXT}>{value === null || value === undefined ? "----" : value.length >= maxLength ? value.substring(0, maxLength) + "..." : value}</CustomText>
        }
        <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={editSaveValue}>{editingValue ? i18n.t("save") : i18n.t("edit")}</CustomButton>
      </div>
      {includeCheckbox ? <CheckboxDefaultValue value={value} setValue={setValue} defaultValue={defaultValue} text={checkboxText} setEditingValue={setEditingValue} /> : <div></div>}
    </div>
  );
}

// Define default props
SimpleStoreCopyField.defaultProps = {
  checkFunction: (val) => val !== null && val !== undefined && val !== "",
  checkWarningFunction: (val) => true,
  warningText: null,
  maxLength: 22,
  infoText: null,
  isInputNumeric: false,
  includeCheckbox: false,
};


const styles = {
  section: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: 'stretch',
    marginBottom: "2vh",
    marginTop: "1vh",
    width: "100%"

  },
  input: {
    backgroundColor: "var(--primary-color-5)",
    color: "var(--primary-color-3)",
    fontSize: 16,

  }
}









